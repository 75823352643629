import React, {useCallback} from 'react';
import { useForm } from 'react-final-form';
import uuidv4 from 'uuid/dist/v4';
import {
    List,
    Datagrid,
    TextField,
    DeleteButton,
    Edit,
    SimpleForm,
    TextInput,
    Create,
    ImageField,
    Pagination,
    ReferenceInput,
    AutocompleteInput,
    NumberInput,
    ArrayInput,
    SimpleFormIterator,
    Toolbar,
    SaveButton,
    fetchUtils
} from 'react-admin';

import { cloneElement} from 'react';
import { TopToolbar, CreateButton, sanitizeListRestProps } from 'react-admin';
import { FormDataConsumer } from 'react-admin';


import './highlighted.styels.scss'
import EditIcon from '@material-ui/icons/Create';
import LaunchIcon from '@material-ui/icons/Launch';
import { useNotify } from 'react-admin';

import CustomAnchorLidField from '../../components/custom-anchor-lid-field/custom-anchor-lid-field.component';


const HighlightedEditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);

const ListActionsTypeZero = ({
                         currentSort,
                         className,
                         resource,
                         filters,
                         displayedFilters,
                         exporter, // you can hide ExportButton if exporter = (null || false)
                         filterValues,
                         permanentFilter,
                         hasCreate, // you can hide CreateButton if hasCreate = false
                         basePath,
                         selectedIds,
                         onUnselectItems,
                         showFilter,
                         maxResults,
                         total,
                         ...rest
                     }) => {
    return <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        {filters && cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        })}
        <CreateButton
            className={'addTypeZero addButtonHighlighted'}
            label="Add highlighted localities top"
            // basePath={basePath+'?type=0'}
            basePath={'highlighted-localities-top'}
        />
    </TopToolbar>
};

ListActionsTypeZero.defaultProps = {
    selectedIds: [],
    onUnselectItems: () => null,
};

const ListActionsTypeOne = ({
                                 currentSort,
                                 className,
                                 resource,
                                 filters,
                                 displayedFilters,
                                 exporter, // you can hide ExportButton if exporter = (null || false)
                                 filterValues,
                                 permanentFilter,
                                 hasCreate, // you can hide CreateButton if hasCreate = false
                                 basePath,
                                 selectedIds,
                                 onUnselectItems,
                                 showFilter,
                                 maxResults,
                                 total,
                                 ...rest
                             }) => {
    return <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        {filters && cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        })}
        <CreateButton
            className={'addTypeOne addButtonHighlighted'}
            label="Add highlighted localities cities"
            // basePath={basePath+'?type=0'}
            basePath={'highlighted-localities-cities'}
        />
    </TopToolbar>
};

ListActionsTypeOne.defaultProps = {
    selectedIds: [],
    onUnselectItems: () => null,
};


const ListActionsTypeTwo = ({
                                 currentSort,
                                 className,
                                 resource,
                                 filters,
                                 displayedFilters,
                                 exporter, // you can hide ExportButton if exporter = (null || false)
                                 filterValues,
                                 permanentFilter,
                                 hasCreate, // you can hide CreateButton if hasCreate = false
                                 basePath,
                                 selectedIds,
                                 onUnselectItems,
                                 showFilter,
                                 maxResults,
                                 total,
                                 ...rest
                             }) => {
    return <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        {filters && cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        })}
        <CreateButton
            className={'addTypeTwo addButtonHighlighted'}
            label="Add highlighted localities seaside"
            // basePath={basePath+'?type=0'}
            basePath={'highlighted-localities-seaside'}
        />
    </TopToolbar>
};

ListActionsTypeTwo.defaultProps = {
    selectedIds: [],
    onUnselectItems: () => null,
};

const ListActionsTypeThree = ({
                                currentSort,
                                className,
                                resource,
                                filters,
                                displayedFilters,
                                exporter, // you can hide ExportButton if exporter = (null || false)
                                filterValues,
                                permanentFilter,
                                hasCreate, // you can hide CreateButton if hasCreate = false
                                basePath,
                                selectedIds,
                                onUnselectItems,
                                showFilter,
                                maxResults,
                                total,
                                ...rest
                            }) => {
    return <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        {filters && cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        })}
        <CreateButton
            className={'addTypeThree addButtonHighlighted'}
            label="Add highlighted localities mountains"
            // basePath={basePath+'?type=0'}
            basePath={'highlighted-localities-mountains'}
        />
    </TopToolbar>
};

ListActionsTypeThree.defaultProps = {
    selectedIds: [],
    onUnselectItems: () => null,
};


const EmptyTable = () => {
    return <table className="MuiTable-root highlighted-list-empty">
        <thead className="MuiTableHead-root">
        <tr className="MuiTableRow-root MuiTableRow-head">
            <th className="MuiTableCell-root MuiTable-head MuiTableCell-sizeSmall"><p className="highlighted-list-empty-p">There are no records yet.</p></th>
        </tr>
        </thead>
        <tbody className="MuiTableBody-root datagrid-body">
        <tr className="MuiTableRow-root MuiTableRow-hover MuiTableCell-root MuiTableCell-body column-full_lid MuiTableCell-sizeSmall">
        </tr>
        </tbody>
    </table>
};

const CustomEditButtonTypeZero = ({ record = {} }) =>
    <span>
        <a className="customEditButton" href={`#/highlighted-localities-top/${record.id}`}>
            <EditIcon/> EDIT
        </a>
    </span>;

const CustomEditButtonTypeOne = ({ record = {} }) =>
    <span>
        <a className="customEditButton" href={`#/highlighted-localities-cities/${record.id}`}>
            <EditIcon/> EDIT
        </a>
    </span>;

const CustomEditButtonTypeTwo = ({ record = {} }) =>
    <span>
        <a className="customEditButton" href={`#/highlighted-localities-seaside/${record.id}`}>
            <EditIcon/> EDIT
        </a>
    </span>;

const CustomEditButtonTypeThree = ({ record = {} }) =>
    <span>
        <a className="customEditButton" href={`#/highlighted-localities-mountains/${record.id}`}>
            <EditIcon/> EDIT
        </a>
    </span>;


const CustomNumberWebcams = ({ record = {} }) => {
    if(record.numberWebcams !== 0){
        return <span>{record.numberWebcams}</span>
    }else{
        return <span className="custom-number-webcams">No webcams</span>
    }
}


const HighlightedTitleCreate = ({ record }) => {
 return <span>Add highlighted localities</span>;
};

const HighlightedTitleUpdate = ({ record }) => {
    return <span>Update highlighted locality {record ? `"${record.name}"` : ''}</span>;
};

const CustomNumberInput = ({ record }) => {
    let maxPosition = parseInt(record.maxPosition) - 1;
    return <NumberInput className="custom-number-input" required label="Position" source="position" inputProps={{
        step: 1,
        min: 1,
        max: maxPosition
    }}/>;
};



const EmptyTypeZero = props =>
    <div>
        <ListActionsTypeZero {...props} />
        <EmptyTable/>
    </div>
;

const EmptyTypeOne = props =>
    <div>
        <ListActionsTypeOne {...props} />
        <EmptyTable/>
    </div>
;


const EmptyTypeTwo = props =>
    <div>
        <ListActionsTypeTwo {...props} />
        <EmptyTable/>
    </div>
;

const EmptyTypeThree = props =>
    <div>
        <ListActionsTypeThree {...props} />
        <EmptyTable/>
    </div>
;


const HighlightedPagination = props => <Pagination rowsPerPageOptions={[]} perPage={10000000000} {...props} />;


export const HighlightedList = props => {
    return(
        <div>
            <List title={'Highli'} empty={<EmptyTypeZero/>} exporter={false} {...props} actions={<ListActionsTypeZero/>} pagination={<HighlightedPagination />} filter={{type : 0}} bulkActionButtons={false} >
                <Datagrid className="highlighted-list">
                    <CustomAnchorLidField label="LID" source="full_lid" sortable={false} />
                    <TextField label="PID" source="pid" sortable={false} />
                    <TextField label="RID" source="rid" sortable={false} />
                    <CustomNumberWebcams label="NUMBER WEBCAMS" source="numberWebcams" sortable={false}/>
                    <ImageField className="webcamPreviewList" label="Preview" source="thumbnail" title="webcam" sortable={false}/>
                    <CustomEditButtonTypeZero  />
                    <DeleteButton undoable={false} />
                </Datagrid>
            </List>

            <List title={'ghted '} empty={<EmptyTypeOne/>} exporter={false} {...props} actions={<ListActionsTypeOne/>} pagination={<HighlightedPagination />} filter={{type : 1}} bulkActionButtons={false} >
                <Datagrid className="highlighted-list">
                    <CustomAnchorLidField label="LID" source="full_lid" sortable={false} />
                    <TextField label="PID" source="pid" sortable={false} />
                    <TextField label="RID" source="rid" sortable={false} />
                    <CustomNumberWebcams label="NUMBER WEBCAMS" source="numberWebcams" sortable={false}/>
                    <ImageField className="webcamPreviewList" label="Preview" source="thumbnail" title="webcam" sortable={false}/>
                    <CustomEditButtonTypeOne  />
                    <DeleteButton undoable={false} />
                </Datagrid>
            </List>

            <List title={'locali'} empty={<EmptyTypeTwo/>} exporter={false} {...props} actions={<ListActionsTypeTwo/>} pagination={<HighlightedPagination />} filter={{type : 2}} bulkActionButtons={false} >
                <Datagrid className="highlighted-list">
                    <CustomAnchorLidField label="LID" source="full_lid" sortable={false} />
                    <TextField label="PID" source="pid" sortable={false} />
                    <TextField label="RID" source="rid" sortable={false} />
                    <CustomNumberWebcams label="NUMBER WEBCAMS" source="numberWebcams" sortable={false}/>
                    <ImageField className="webcamPreviewList" label="Preview" source="thumbnail" title="webcam" sortable={false}/>
                    <CustomEditButtonTypeTwo  />
                    <DeleteButton undoable={false} />
                </Datagrid>
            </List>

            <List title={'ties'} empty={<EmptyTypeThree/>} exporter={false} {...props} actions={<ListActionsTypeThree/>} pagination={<HighlightedPagination />} filter={{type : 3}} bulkActionButtons={false} >
                <Datagrid className="highlighted-list">
                    <CustomAnchorLidField label="LID" source="full_lid" sortable={false} />
                    <TextField label="PID" source="pid" sortable={false} />
                    <TextField label="RID" source="rid" sortable={false} />
                    <CustomNumberWebcams label="NUMBER WEBCAMS" source="numberWebcams" sortable={false}/>
                    <ImageField className="webcamPreviewList" label="Preview" source="thumbnail" title="webcam" sortable={false}/>
                    <CustomEditButtonTypeThree  />
                    <DeleteButton undoable={false} />
                </Datagrid>
            </List>
        </div>
    )
};


export const HighlightedEdit = props => {
    const validateUpdate = (values) => {
        let maxPosition = parseInt(values.maxPosition) - 1;
        const errors = {};
        if (!values.lid) {
            errors.lid = ['Lid is required'];
        }else if(!Number.isInteger(parseInt(values.lid))){
            errors.lid = ['Lid must be an integer'];
        }

        if (!values.name) {
            errors.name = ['Name is required'];
        }

        if (!values.position) {
            errors.name = ['Position is required'];
        }

        if (values.position > maxPosition) {
            errors.position = [`Max position is ${maxPosition}`];
        }

        return errors
    };

    return <Edit className="highlighted-edit" title={<HighlightedTitleUpdate />} {...props}>
        <SimpleForm validate={validateUpdate} redirect={"/highlighted"} toolbar={<HighlightedEditToolbar />}>
            <TextInput required label="lid" source="full_lid" disabled />
            <TextInput required source="name" disabled />
            <CustomNumberInput />
        </SimpleForm>
    </Edit>
};


const httpClient = (url, options = {}) => {
    let jwt = localStorage.getItem('token');
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    // add your own headers here
    options.headers = new Headers({'Authorization': `Bearer ${jwt}`});
    // options.mode = 'no-cors';
    return fetchUtils.fetchJson(url, options);
};

const CustomSaveHighlightedButton = ({ handleSubmitWithRedirect, ...props }) => {
    const notify = useNotify();
    const form = useForm();
    // get values from the form

    const checkIfAllLocalitiesHasWebcams = useCallback(() => {
        // change the average_note field value
        let withWebcams = document.getElementsByClassName('spanActiveInformation');
        let withoutWebcams = document.getElementsByClassName('noActiveWebcams');
        if(withWebcams.length === 0){
            notify('Please select at least one location with webcams', 'warning');
            return false;
        }

        if(withoutWebcams.length !== 0){
            notify('Attention you have selected locations without webcams', 'warning');
            return false;
        }

        handleSubmitWithRedirect('/highlighted');
    }, [form]);

    return <SaveButton
        id="highlightedCreateSaveButton"
        label="SAVE"
        redirect="/highlighted"
        submitOnEnter={true}
        handleSubmitWithRedirect={checkIfAllLocalitiesHasWebcams}
    />;
};

const HighlightedCreateToolbar = props => (
    <Toolbar {...props} >
        <CustomSaveHighlightedButton/>
    </Toolbar>
);


export const HighlightedCreate = props => {

        return <Create title={<HighlightedTitleCreate />} {...props}>
        <SimpleForm toolbar={<HighlightedCreateToolbar />}  redirect={"/highlighted"}>

            {/*<ReferenceInput label="LID*" source="lid" reference="webcams" filterToQuery={searchText => ({ title: searchText })}>*/}
                {/*<AutocompleteInput optionText="name" />*/}
            {/*</ReferenceInput>*/}

            <ArrayInput label="Localities" source="localities">
                <SimpleFormIterator>
                    {/*<CustomLocalityReferenceInput {...props}/>*/}
                    {/*<TextInput label="Webcams" source="wids_id" />*/}
                    <ReferenceInput
                        label="LID"
                        source="lid_ids"
                        reference="localities"
                        filterToQuery={searchText => ({ title: searchText })}>
                        <AutocompleteInput optionText="name"/>
                    </ReferenceInput>
                    <FormDataConsumer>
                        {({ getSource, scopedFormData }) => {
                            if(scopedFormData !== undefined){
                                let uid = uuidv4();
                                let anchorId = 'anchor-'+uid;
                                let apiUrl = window.REACT_APP_API_URL || 'http://localhost:8090';
                                let url = `${apiUrl}/webcams/lid/${scopedFormData.lid_ids}?start=0&limit=100000&valid=1&active=1`;
                                httpClient(url).then( data => {
                                    var div = document.getElementById(uid);
                                    var a = document.getElementById(anchorId);
                                    if(parseInt(data.json.resultSetCount) > 0){
                                        if(div !== null){
                                            div.innerHTML = `Number of active webcams ${data.json.resultSetCount}`;
                                            a.style.display = "inline-block";
                                            a.href = `/#/webcams?filter=%7B"lid"%3A${scopedFormData.lid_ids}%7D&order=ASC&page=1&perPage=10&sort=id`;
                                        }
                                    }else{
                                        if(div !== null) {
                                            div.innerHTML = `<span class="noActiveWebcams" style="color: red">There are no active webcams</span>`;
                                        }
                                    }
                                    return true;
                                });
                                return <ContainerInformation valueOfid={uid} anchorId={anchorId}/>
                            }else{
                                return <span></span>
                            }
                        }}
                    </FormDataConsumer>
                </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    </Create>
};


const ContainerInformation = props => {
    return <div>
        <span className='spanActiveInformation' id={props.valueOfid}></span>
        <a className='anchorActiveInformation' href="/" id={props.anchorId} target='_black'>View<LaunchIcon className='icon' /></a>
    </div>
};