import React, {useState} from 'react';

import EditIcon from '@material-ui/icons/Create';
import {
    Filter,
    List,
    Datagrid,
    TextField,
    Edit,
    SimpleForm,
    TextInput,
    SelectInput,
    Create,
    DateField,
    Toolbar,
    SaveButton,
    DeleteButton,
    ReferenceInput,
    AutocompleteInput,
    Pagination
} from 'react-admin';

import './webcams-report.styles.scss'
import CustomUrlField from '../../components/custom-url-field/custom-url-field.component';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useNotify } from 'react-admin';
import LaunchIcon from '@material-ui/icons/Launch';


let wrLid;
let wrDescription;
let wrWebcamUrl;
let wrWebsite;

const ReportsEditToolbar = props => (
    <Toolbar {...props} className='hkEditToolBar'>
        <a className="MuiButtonBase-root MuiButton-root MuiButton-contained hkBackButton" {...props} href={`#/reports`}><ArrowBackIcon/> Back</a>
        <SaveButton submitOnEnter={false}/>
        <CustomAddAsWebcamEditButton/>
        <DeleteButton label="reject" className="wbDeleteButton"/>
    </Toolbar>
);

function checkIfTheUrlFormatIsValid(url) {
    let pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
        '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return pattern.test(url);
}

const CustomAddAsWebcamButton = ({ record }) => {
  if(parseInt(record.status) === 1){
      return null;
  }else{
      return <div>
          <span>
            <a className="customEditButton" href={`#/reports-add-as-webcam/${record.id}`}>
                <AddIcon/> ADD AS WEBCAM
            </a>
          </span>
      </div>
  }
};

const CustomAddAsWebcamEditButton = ({ record = {} }) =>{
    const notify = useNotify();
    if(parseInt(record.status) === 1){
        return null;
    }else{
        if(wrDescription !== record.description || wrLid !== record.lid || wrWebcamUrl !== record.webcam_url || wrWebsite !== record.website){
            notify('To continue save the changes first', 'warning');
            return <span>
        <div className="customEditButton disabled" disabled>
            <AddIcon/> ADD AS WEBCAM
        </div>
    </span>;
        }
        return <span>
        <a className="customEditButton" href={`#/reports-add-as-webcam/${record.id}`}>
            <AddIcon/> ADD AS WEBCAM
        </a>
    </span>;
    }
}

// const CustomRejectedEditButton = ({ handleSubmitWithRedirect, ...props }) => {
//     const notify = useNotify();
//     if(wrDescription !== record.description || wrLid !== record.lid || wrWebcamUrl !== record.webcam_url || wrWebsite !== record.website){
//         notify('To continue save the changes first', 'warning');
//         return <span>
//             <div className="wbDeleteButton disabled" disabled>
//                 <AddIcon/> Rejected
//             </div>
//         </span>;
//     }
//
//     // override handleSubmitWithRedirect with custom logic
//     return <DeleteButton {...props}/>;
// };
//
//
// const CustomRejectedEditButton = ({ record = {} }) =>{
//     // const notify = useNotify();
//     if(wrDescription !== record.description || wrLid !== record.lid || wrWebcamUrl !== record.webcam_url || wrWebsite !== record.website){
//         notify('To continue save the changes first', 'warning');
//         return <span>
//             <div className="wbDeleteButton disabled" disabled>
//                 <AddIcon/> Rejected
//             </div>
//         </span>;
//     }
//     return <DeleteButton className="wbDeleteButton"/>;
// }

const WidField = ({ record, ...rest }) => {
    if(record){
        if(record.wid !== null && parseInt(record.status) === 1){
            return <a href={`#/webcams/${record.wid}`} className="link" target="_blank" rel="noopener noreferrer">
                {record.wid}
                <LaunchIcon className='icon' />
            </a>
        }else {
            return null
        }
    }else{
        return null;
    }
}

const ImgWebcam = ({src}) => (
    <div>
        <p>Preview</p>
        <div class="container-image-wb-form">
            <img src={src} class="wb-form-image" alt="wb-form"/>
        </div>
    </div>
);

const WebcamReportsTitle = ({ record }) => {
 return <span>Record {record ? `"${record.id}"` : ''}</span>;
};

const WebcamReportsFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Locality" source="city" alwaysOn resettable/>
        <TextInput label="Reporter email" source="email" alwaysOn resettable/>
        <SelectInput label="Status" allowEmpty={false} source="statusText" alwaysOn resettable
                     choices={[
                         {id: -1, name: "Rejected"},
                         {id: 0, name: "Pending"},
                         {id: 1, name: "Live"}
                     ]}
        />
    </Filter>
);

const CustomEditButton = ({ record = {} }) =>
    <span>
        <a className="customEditButton" href={`#/reports/${record.id}`}>
            <EditIcon/> EDIT
        </a>
    </span>;


const ItemPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
export const WebcamReportsList = props => {
    return(
        <List filters={<WebcamReportsFilter/>} exporter={false} bulkActionButtons={false} {...props} perPage={100} pagination={<ItemPagination />}>
            <Datagrid className="webcams-reports-list">
                <TextField label="Locality" source="city"/>
                <TextField label="Reporter email" source="email"/>
                <CustomUrlField label="Webcam URL" source="webcam_url" sortable={false}/>
                <CustomUrlField label="Source website" source="website" sortable={false}/>
                <TextField label="Status" source="statusText"/>
                <WidField label="Created webcam" sortable={false}/>
                <DateField label="Reported on" source="createdAt" showTime  locales='it-IT'/>
                <CustomAddAsWebcamButton/>
                <CustomEditButton/>
            </Datagrid>
        </List>
    )
};


export const WebcamReportsEdit = props =>
    {const [url, setUrl] = useState("");
        const validateCreate = (values) => {
            const errors = {};
            if (!values.lid) {
                errors.lid = ['Lid is required'];
            }else if(!Number.isInteger(parseInt(values.lid))){
                errors.lid = ['Lid must be an integer'];
            }
            // if (!values.name) {
            //     errors.name = ['Name is required'];
            // }
            if (!values.webcam_url) {
                errors.webcam_url = ['Url is required'];
            }else if(!checkIfTheUrlFormatIsValid(values.webcam_url)){
                errors.webcam_url = ['Url wrong format'];
            }

            if (values.webcam_url && checkIfTheUrlFormatIsValid(values.webcam_url) &&  values.webcam_url.length !== 0) {
                setUrl(values.webcam_url);
            }

            wrLid = values.lid;
            wrDescription = values.description;
            wrWebcamUrl = values.webcam_url;
            wrWebsite = values.website;

            // console.log(errors);
            return errors
        };

        return <Edit title={<WebcamReportsTitle />} {...props}>
            <SimpleForm validate={validateCreate} redirect={"/reports"} toolbar={<ReportsEditToolbar />}>
                <ReferenceInput label="Locality" source="lid" reference="localities" filterToQuery={searchText => ({ title: searchText })}>
                    <AutocompleteInput optionText="name" />
                </ReferenceInput>
                <TextInput label="Email" source="email"/>
                <TextInput source="name" />
                <TextInput multiline source="description" />
                <TextInput label="URL" source="webcam_url"/>
                <TextInput label="Website" source="website"/>
                <ImgWebcam src={url}/>
            </SimpleForm>
        </Edit>
    }




const AddAsWebcamToolbar = props => (
    <Toolbar {...props} className='hkEditToolBar'>
        <a className="MuiButtonBase-root MuiButton-root MuiButton-contained hkBackButton" {...props} href={`#/reports`}><ArrowBackIcon/> Back</a>
        <SaveButton submitOnEnter={false}/>
    </Toolbar>
);



export const WebcamReportsAddAsWebcam = props =>
{const [url, setUrl] = useState("");
    const validateCreate = (values) => {
        const errors = {};
        if (!values.lid) {
            errors.lid = ['Lid is required'];
        }else if(!Number.isInteger(parseInt(values.lid))){
            errors.lid = ['Lid must be an integer'];
        }
        if (!values.name) {
            errors.name = ['Name is required'];
        }
        if (!values.webcam_url) {
            errors.webcam_url = ['Url is required'];
        }else if(!checkIfTheUrlFormatIsValid(values.webcam_url)){
            errors.webcam_url = ['Url wrong format'];
        }

        if (values.webcam_url && checkIfTheUrlFormatIsValid(values.webcam_url) &&  values.webcam_url.length !== 0) {
            setUrl(values.webcam_url);
        }

        // console.log(errors);
        return errors
    };

    return <Edit title={<WebcamReportsTitle />} {...props}>
        <SimpleForm validate={validateCreate} redirect={"/reports"} toolbar={<AddAsWebcamToolbar />}>
            <ReferenceInput label="Locality" source="lid" reference="localities" filterToQuery={searchText => ({ title: searchText })}>
                <AutocompleteInput optionText="name" />
            </ReferenceInput>
            {/*<TextInput required label="LID" source="lid"/>*/}
            <TextInput required source="name" />
            <TextInput multiline source="description" />
            <TextInput required label="URL" source="webcam_url"/>
            <ImgWebcam src={url}/>
        </SimpleForm>
    </Edit>
}

// export const WebcamReportsEdit = props => (
//     <Edit title={<WebcamReportsTitle />} {...props}>
//         <SimpleForm redirect={"/reports"}>
//             <TextInput source="lid" />
//             <TextInput source="name" />
//             <TextInput source="url" />
//             <TextInput source="image" />
//             <TextInput source="source" />
//             <TextInput source="status" />
//         </SimpleForm>
//     </Edit>
// );

export const WebcamReportsCreate = props => (
    <Create {...props}>
        <SimpleForm redirect={"/webcams"}>
            <TextInput source="lid" />
            <TextInput source="name" />
            <TextInput multiline source="description" />
            <TextInput source="url" />
            <TextInput source="image" />
            <TextInput source="source" />
            <TextInput source="status" />
        </SimpleForm>
    </Create>
);

/*
redirect={"/webcams"}*/
