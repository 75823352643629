import React from 'react';
import LaunchIcon from '@material-ui/icons/Launch';

import './custom-anchor-lid-field.styels.scss'

const CustomAnchorLidField = ({ record = {}, source }) => {
    let lid = record['lid'];
    let link = `/#/webcams?filter=%7B"lid"%3A${lid}%7D&order=ASC&page=1&perPage=10&sort=id`
    return <a href={link} className="link" target="_blank" rel="noopener noreferrer">
        {record[source]}
        <LaunchIcon className='icon' />
    </a>;
}

export default CustomAnchorLidField;