import React, {useState} from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import {
    Filter,
    List,
    Datagrid,
    TextField,
    EditButton,
    DeleteButton,
    Edit,
    SimpleForm,
    TextInput,
    Create,
    ImageField,
    ChipField,
    SelectInput,
    Show,
    SimpleShowLayout,
    RichTextField,
    UrlField,
    Pagination,
    DateField,
    ReferenceInput,
    AutocompleteInput,
    Toolbar,
    SaveButton,
    NumberInput,
    NumberField,
    BooleanInput
} from 'react-admin';
import './webcams.styles.scss'
import { makeStyles } from '@material-ui/core/styles';

import CustomUrlField from '../../components/custom-url-field/custom-url-field.component';
// import { EditableDatagrid, RowForm } from '@react-admin/ra-editable-datagrid';


function checkIfTheUrlFormatIsValid(url) {
    let pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
        '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return pattern.test(url);
}

const useStyles = makeStyles({
    priority: { color: 'red' },
});

const PriorityField = ({ record, ...rest }) => {
    const classes = useStyles();
    if(record){
        if((Math.sign(record.position) === -1)){
            return <span className={classes.priority}>HIDDEN</span>
        }else {
            return <NumberField label="Priority" source="position" record={record} {...rest} />
        }
    }else{
        return null;
    }
}



const WebcamEditToolbar = props => (
    <Toolbar {...props} className='wbEditToolBar'>
        <a className="MuiButtonBase-root MuiButton-root MuiButton-contained wbBackButton" href={`#/webcams`}><ArrowBackIcon/> Back</a>
        <SaveButton />
        <DeleteButton className="wbDeleteButton"/>
    </Toolbar>
);

const WebcamEditInlineToolbar = props => (
    <Toolbar {...props}>
        <SaveButton />
    </Toolbar>
);


const WebcamTitleCreate = ({ record }) => {
 return <span>Create webcam</span>;
}
const WebcamTitleUpdate = ({ record }) => {
    return <span>Update webcam {record ? `"${record.name}"` : ''}</span>;
};


const SnippetInput = ({ record }) => {
    if(record){
        if(parseInt(record.sourceType) === 1 || parseInt(record.sourceType) === 2){
            return <TextInput className="sourceWebcamInput" required label="URL" source="snippet" />
        }else{
            return <TextInput className="sourceWebcamInput" disabled required label="URL" source="snippet" />
        }
    }else{
        return null;
    }
};

const WebcamFilter = (props) => {
    if(props.filterValues.lid === undefined){
        if(props.filterValues.show_hamlet !== undefined)props.filterValues.show_hamlet = undefined;
        return(
            <Filter {...props}>
                <ReferenceInput label="Locality" source="lid" reference="filter-localities" filterToQuery={searchText => ({ title: searchText })} alwaysOn helperText='test'>
                    <AutocompleteInput optionText="name"/>
                </ReferenceInput>
                <SelectInput allowEmpty={false} source="source" alwaysOn resettable
                             choices={[
                                 {id: 1, name: "Legacy"},
                                 {id: 2, name: "Manual"},
                                 {id: 3, name: "Skyline"},
                                 {id: 4, name: "Windy"},
                                 {id: 5, name: "Feratel"},
                                 {id: 6, name: "LiveinCam"},
                             ]}
                />
                <BooleanInput id='show_hamlet' source="show_hamlet" label="Show related localities" alwaysOn disabled/>
            </Filter>
        )
    }else{
        return(
            <Filter {...props}>
                <ReferenceInput label="Locality" source="lid" reference="filter-localities" filterToQuery={searchText => ({ title: searchText })} alwaysOn helperText='test'>
                    <AutocompleteInput optionText="name"/>
                </ReferenceInput>
                <SelectInput allowEmpty={false} source="source" alwaysOn resettable
                             choices={[
                                 {id: 1, name: "Legacy"},
                                 {id: 2, name: "Manual"},
                                 {id: 3, name: "Skyline"},
                                 {id: 4, name: "Windy"},
                                 {id: 5, name: "Feratel"},
                                 {id: 6, name: "LiveinCam"},
                             ]}
                />
                <BooleanInput id='show_hamlet' source="show_hamlet" label="Show related localities" alwaysOn/>
            </Filter>
        )
    }
};

const ImgWebcam = ({src }) => (
    <div>
        <p>Preview</p>
        <div class="container-image-wb-form">
            <img src={src} class="wb-form-image" alt="wb-form"/>
        </div>
    </div>
);

const ItemPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />


const InlinePositionEdit = props => {
    const redirect = window.location.href;
    let queryString = redirect.split("?");

    const validateInlinePositionEdit = (values) => {
        const errors = {};
        if(values.position && !Number.isInteger(parseInt(values.position))){
            errors.position = ['Priority must be an integer'];
        }
        // console.log(errors);
        return errors
    };
    return(
    <Edit
        {...props}
        /* disable the app title change when shown */
        title=" "
    >
        <SimpleForm
            redirect={`webcams?${queryString[1]}`}
            validate={validateInlinePositionEdit}
            toolbar={<WebcamEditInlineToolbar />}
        >
            <NumberInput label="Priority" source="position" step={1} />
        </SimpleForm>
    </Edit>
    )
};

export const WebcamList = props => {
    return(
        <List filters={<WebcamFilter/>} exporter={false} {...props} perPage={10} pagination={<ItemPagination />}>
            <Datagrid className="webcams-list" expand={<InlinePositionEdit  />}>
                <TextField label="WID" source="wid" />
                <TextField label="LID" source="full_lid" />
                <TextField source="name" />
                <CustomUrlField label="URL" source="snippet" sortable={false} />
                <TextField source="description" sortable={false} />
                <ChipField source="source" />
                <PriorityField source="position" />
                <DateField source="updatedAt" showTime  locales='it-IT'/>
                <ImageField className="webcamPreviewList" label="Preview" source="thumbnail" title="webcam" sortable={false}/>
                <EditButton />
            </Datagrid>
        </List>
    )
};

// export const WebcamEdit = props => (
//     <Edit title={<WebcamTitleUpdate />} {...props}>
//         <SimpleForm validate={validateUpdate} redirect={"/webcams"}>
//             <ReferenceInput label="LID*" source="lid" reference="localities" filterToQuery={searchText => ({ title: searchText })}>
//                 <AutocompleteInput optionText="name"/>
//             </ReferenceInput>
//             <TextInput required source="name" />
//             <TextInput multiline source="description" />
//             <TextInput required label="URL" source="snippet" />
//         </SimpleForm>
//     </Edit>
// );

export const WebcamEdit = props => {
    // console.log(props);
    const[urlWebcamImage, setUrlWebcamImage] = useState(props.snippet);

    const validateUpdate = (values) => {
        const errors = {};
        if (!values.lid) {
            errors.lid = ['Lid is required'];
        }else if(!Number.isInteger(parseInt(values.lid))){
            errors.lid = ['Lid must be an integer'];
        }

        if (!values.name) {
            errors.name = ['Name is required'];
        }
        if (!values.snippet) {
            errors.snippet = ['Url is required'];
        }else if(!checkIfTheUrlFormatIsValid(values.snippet)){
            errors.snippet = ['Url wrong format'];
        }
        if (values.snippet && checkIfTheUrlFormatIsValid(values.snippet) &&  values.snippet.length !== 0) {
            if(parseInt(values.sourceType) === 1 || parseInt(values.sourceType) === 2){
                setUrlWebcamImage(values.snippet);
            }else{
                setUrlWebcamImage(values.thumbnail);
            }
        }
        if(values.position && !Number.isInteger(parseInt(values.position))){
            errors.position = ['Priority must be an integer'];
        }

        // if(parseInt(values.sourceType) == 1 || parseInt(values.sourceType) == 2){
        //     document.getElementById("snippet").disabled = true;
        // }

        return errors
    };


    return <Edit className="webcam-edit" title={<WebcamTitleUpdate />} {...props}>
        <SimpleForm validate={validateUpdate} redirect={"/webcams"} toolbar={<WebcamEditToolbar />}>
            <ReferenceInput label="Locality*" source="lid" reference="localities" filterToQuery={searchText => ({ title: searchText })}>
                <AutocompleteInput optionText="name"/>
            </ReferenceInput>
            <TextInput required source="name" />
            <TextInput multiline source="description" />
            <NumberInput label="Priority" source="position" step={1} />
            <SnippetInput />
            <ImgWebcam src={urlWebcamImage}/>
            <ChipField source="source" />
            <DateField source="updatedAt" showTime  locales='it-IT' sortable={false}/>
        </SimpleForm>
    </Edit>
};



export const WebcamCreate = props =>
    {const [url, setUrl] = useState("");
        const validateCreate = (values) => {
            const errors = {};
            if (!values.lid) {
                errors.lid = ['Lid is required'];
            }else if(!Number.isInteger(parseInt(values.lid))){
                errors.lid = ['Lid must be an integer'];
            }

            if (!values.name) {
                errors.name = ['Name is required'];
            }
            if (!values.snippet) {
                errors.snippet = ['Url is required'];
            }else if(!checkIfTheUrlFormatIsValid(values.snippet)){
                errors.snippet = ['Url wrong format'];
            }

            if (values.snippet && checkIfTheUrlFormatIsValid(values.snippet) &&  values.snippet.length !== 0) {
                setUrl(values.snippet);
            }

            if(values.position && !Number.isInteger(parseInt(values.position))){
                errors.position = ['Priority must be an integer'];
            }

            // console.log(errors);
            return errors
        };

        return <Create title={<WebcamTitleCreate />} {...props}>
        <SimpleForm validate={validateCreate} redirect={"/webcams"}>

            <ReferenceInput label="LID*" source="lid" reference="localities" filterToQuery={searchText => ({ title: searchText })}>
                <AutocompleteInput optionText="name" />
            </ReferenceInput>

            <TextInput required source="name" />
            <TextInput multiline source="description" />
            <NumberInput label="Priority" source="position" step={1} />
            <TextInput required label="URL" source="snippet" />
            <ImgWebcam src={url}/>
        </SimpleForm>
    </Create>
}

export const WebcamShow = (props) => (
    <Show title="Webcam view" {...props}>
        <SimpleShowLayout>
            <TextField label="WID" source="wid"/>
            <TextField label="LID" source="locality.lid" />
            <TextField source="name" />
            <RichTextField source="description" />
            <UrlField label="URL" source="snippet" />
            <ChipField source="source" />
            <DateField source="updatedAt" showTime  locales='it-IT' sortable={false}/>
            <ImageField className="wb-image" label="Preview" source="thumbnail" title="webcam"/>
        </SimpleShowLayout>
    </Show>
)

