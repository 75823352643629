import React from 'react';
// import { Admin } from 'react-admin';
import { Admin, Resource, Login} from 'react-admin';
import { WebcamList, WebcamEdit, WebcamCreate } from './page/webcams/webcams.component';
import { WebcamReportsList, WebcamReportsEdit, WebcamReportsAddAsWebcam } from './page/webcam-reports/webcam-reports.component';
import { HousekeepingList, HousekeepingEdit } from './page/housekeeping/housekeeping.component';
import { DeletedWebcamsList, DeletedWebcamsEdit} from './page/deleted-webcams/deleted-webcams.component';
import { HighlightedList, HighlightedEdit, HighlightedCreate} from './page/highlighted/highlighted.component';

import authProvider from './provider/auth-provider/auth-provider.component';

import WebcamIcon from '@material-ui/icons/LinkedCamera';
import HousekeepingIcon from '@material-ui/icons/BrokenImage';
import WebcamReportsIcon from '@material-ui/icons/RecordVoiceOver';
import WebcamDeletedIcon from '@material-ui/icons/Delete';
import HighlightedIcon from '@material-ui/icons/Stars';
import './custom.styels.scss'

import dataProvider from './provider/data-provider/data-provider.component';

const CustomLoginPage = () => (
    <Login
        // A random image that changes everyday
        backgroundImage="/images/bg.jpg"
    />
);


const App = () => (
    <Admin loginPage={CustomLoginPage} authProvider={authProvider} dataProvider={dataProvider}>
      <Resource icon={WebcamIcon} name="webcams" list={WebcamList} edit={WebcamEdit} create={WebcamCreate} />
      <Resource icon={HousekeepingIcon} options={{label: 'Housekeeping'}} name="housekeeping" list={HousekeepingList} edit={HousekeepingEdit}/>
      <Resource icon={WebcamDeletedIcon} options={{label: 'Deleted Webcams', title: 'Deleted Webcams'}} name="deleted-webcams" list={DeletedWebcamsList} edit={DeletedWebcamsEdit}/>
      <Resource icon={HighlightedIcon} options={{label: 'Highlighted localities', title: 'Highlighted localities'}} name="highlighted" list={HighlightedList} edit={HighlightedEdit} create={HighlightedCreate}/>
      <Resource icon={WebcamReportsIcon} options={{label: 'Webcam Reports', title: 'Webcam Reports'}} name="reports" list={WebcamReportsList} edit={WebcamReportsEdit}/>
      <Resource name="localities" />
      <Resource name="filter-localities" />
      <Resource name="reports-add-as-webcam" edit={WebcamReportsAddAsWebcam}/>
      <Resource name="highlighted-localities-top"  create={HighlightedCreate} edit={HighlightedEdit}/>
      <Resource name="highlighted-localities-cities" create={HighlightedCreate} edit={HighlightedEdit}/>
      <Resource name="highlighted-localities-seaside" create={HighlightedCreate} edit={HighlightedEdit}/>
      <Resource name="highlighted-localities-mountains" create={HighlightedCreate} edit={HighlightedEdit}/>
    </Admin>
);

export default App;