import React, {useState} from 'react';
import {
    Filter,
    List,
    Datagrid,
    TextField,
    Edit,
    SimpleForm,
    ReferenceInput,
    TextInput,
    SelectInput,
    ImageField,
    ChipField,
    DateField,
    Show,
    SimpleShowLayout,
    RichTextField,
    UrlField,
    AutocompleteInput,
    Toolbar,
    SaveButton,
    EditButton,
    Pagination
} from 'react-admin';
import './deleted-webcams.styels.scss'
import { useCallback } from 'react';
import { useForm } from 'react-final-form';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CustomUrlField from '../../components/custom-url-field/custom-url-field.component';

const DeletedWebcamsFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput label="Locality" source="lid" reference="filter-localities" filterToQuery={searchText => ({ title: searchText })} alwaysOn>
            <AutocompleteInput optionText="name"/>
        </ReferenceInput>
        <SelectInput allowEmpty={false} source="source" alwaysOn resettable
                     choices={[
                         {id: 1, name: "Legacy"},
                         {id: 2, name: "Manual"},
                         {id: 3, name: "Skyline"},
                         {id: 4, name: "Windy"},
                         {id: 5, name: "Feratel"},
                         {id: 6, name: "LiveinCam"},
                     ]}
        />
    </Filter>
);

const SnippetInput = ({ record }) => {
    if(parseInt(record.sourceType) === 1 || parseInt(record.sourceType) === 2){
        return <TextInput className="sourceWebcamInput" required label="URL" source="snippet" />
    }else{
        return <TextInput className="sourceWebcamInput" disabled required label="URL" source="snippet" />
    }
};

const ImgWebcam = ({src }) => (
    <div>
        <p>Preview</p>
        <div class="container-image-wb-form">
            <img src={src} class="wb-form-image" alt="wb-form-img"/>
        </div>
    </div>
);

function checkIfTheUrlFormatIsValid(url) {
    let pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
        '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return pattern.test(url);
}

const ItemPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />

export const DeletedWebcamsList = props => {
    return(
        <List title={'Deleted webcams'} filters={<DeletedWebcamsFilter/>} bulkActionButtons={false} exporter={false} {...props} perPage={100} pagination={<ItemPagination />}>
            <Datagrid className="deleted-webcams-list">
                <TextField label="WID" source="wid" />
                <TextField label="LID" source="full_lid"/>
                <TextField source="name"/>
                <CustomUrlField label="URL" source="snippet" sortable={false}/>
                <TextField source="description" sortable={false}/>
                <ChipField source="source" />
                <DateField label="Deleted at" source="updatedAt" showTime  locales='it-IT'/>
                <ImageField className="webcamDeletedPreviewList" label="Preview" source="thumbnail" title="webcam" sortable={false}/>
                <EditButton />
            </Datagrid>
        </List>
    )
};

const DeletedWebcamTitle = ({ record }) => {
    return <span>Update webcams {record ? `"${record.name}"` : ''}</span>;
};

let lidValue;

const SaveAndPublish = ({ handleSubmitWithRedirect, ...props }) => {
    const form = useForm();

    const handleClick = useCallback(() => {
        // change the average_note field value
        form.change('valid', 1);
        form.change('active', 1);

        handleSubmitWithRedirect(`/webcams?filter=%7B"lid"%3A${lidValue}%7D&order=ASC&page=1&perPage=10&sort=id`);
    }, [form, handleSubmitWithRedirect]);

    // override handleSubmitWithRedirect with custom logic
    return <SaveButton {...props} handleSubmitWithRedirect={handleClick} />;
};

const DeletedWebcamEditToolbar = props => (
    <Toolbar {...props} className='hkEditToolBar'>
        <a className="MuiButtonBase-root MuiButton-root MuiButton-contained hkBackButton" {...props} href={`#/housekeeping`}><ArrowBackIcon/> Back</a>
        <SaveButton submitOnEnter={false}/>
        <SaveAndPublish
            label="save and publish"
            className='hkSaveAndPublish'
            submitOnEnter={false}
        />
    </Toolbar>
);


export const DeletedWebcamsEdit = props => {
    const[urlWebcamImage, setUrlWebcamImage] = useState(props.snippet);

    const validateUpdate = (values) => {
        const errors = {};
        if (!values.lid) {
            errors.lid = ['Lid is required'];
        }else if(!Number.isInteger(parseInt(values.lid))){
            errors.lid = ['Lid must be an integer'];
        }

        if(values.lid && Number.isInteger(parseInt(values.lid))){
            lidValue = values.lid;
        }

        if (!values.name) {
            errors.name = ['Name is required'];
        }
        if (!values.snippet) {
            errors.snippet = ['Url is required'];
        }else if(!checkIfTheUrlFormatIsValid(values.snippet)){
            errors.snippet = ['Url wrong format'];
        }
        if (values.snippet && checkIfTheUrlFormatIsValid(values.snippet) &&  values.snippet.length !== 0) {
            if(parseInt(values.sourceType) === 1 || parseInt(values.sourceType) === 2){
                setUrlWebcamImage(values.snippet);
            }else{
                setUrlWebcamImage(values.thumbnail);
            }
        }

        return errors
    };


    return <Edit className="deleted-webcam-edit" title={<DeletedWebcamTitle />} {...props}>
        <SimpleForm validate={validateUpdate} redirect={"/housekeeping"} toolbar={<DeletedWebcamEditToolbar />}>
            <ReferenceInput label="Locality" source="lid" reference="localities" filterToQuery={searchText => ({ title: searchText })}>
                <AutocompleteInput optionText="name"/>
            </ReferenceInput>
            <TextInput required source="name"/>
            <TextInput multiline source="description" />
            <SnippetInput />
            <ImgWebcam src={urlWebcamImage}/>
            <ChipField source="source" />
            <DateField label="Checked at" source="updatedAt" showTime  locales='it-IT' sortable={false}/>
        </SimpleForm>
    </Edit>
};

export const DeletedWebcamsShow = (props) => (
    <Show title="Deleted view" {...props}>
        <SimpleShowLayout>
            <TextField label="WID" source="wid"/>
            <TextField label="LID" source="lid" />
            <TextField source="name" />
            <RichTextField source="description" />
            <UrlField label="URL" source="snippet" />
            <ChipField source="source" />
            <DateField label="Deleted at" source="updatedAt" showTime  locales='it-IT' sortable={false}/>
            <ImageField className="wb-image" label="Preview" source="snippet" title="webcam"/>
        </SimpleShowLayout>
    </Show>
)
