import { fetchUtils} from 'react-admin';
import moment from 'moment'

const apiUrl = window.REACT_APP_API_URL || 'http://localhost:8090';

const httpClient = (url, options = {}) => {
    let jwt = localStorage.getItem('token');
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    // add your own headers here
    options.headers = new Headers({'Authorization': `Bearer ${jwt}`});
    // options.mode = 'no-cors';
    return fetchUtils.fetchJson(url, options);
};

function isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}

// let localDataWebcams;

//There is no method for filtering locations by id. Then we will save them all in a global variable of the DataProvider. A.C.
let array_of_localities_ids = [];
let array_of_localities_ids_type_zero = [];
let array_of_localities_ids_type_one = [];
let array_of_localities_ids_type_two = [];
let array_of_localities_ids_type_three = [];

function delay(t, v) {
    return new Promise(function(resolve) {
        setTimeout(resolve.bind(null, v), t)
    });
}

export default {
    getList: (resource, params) => {
        // console.log(resource);
        // console.log(params);
        if(resource === 'localities'){
            return delay(200).then(function() {
                let url;
                let localityName = localStorage.getItem('locality_name');
                let localityLid = localStorage.getItem('locality_lid');
                let filter_by_lid = false;
                if(!isEmpty(params.filter)){
                    let filter_title = params.filter.title;
                    if(filter_title !== ""){
                        url = `${apiUrl}/localities?start=0&limit=50&name=${filter_title}&order_by=population&order_type=desc&nid=IT`;
                    }else{
                        if(localityName){
                            url = `${apiUrl}/localities?start=0&limit=50&name=${localityName}&order_by=population&order_type=desc&nid=IT`;
                            localStorage.removeItem('locality_name');
                            filter_by_lid = true;
                        }else{
                            url = `${apiUrl}/localities?start=0&limit=50&order_by=population&order_type=desc&nid=IT`;
                        }
                    }
                }else{
                    // localStorage.removeItem('locality_name');
                    // localStorage.removeItem('locality_lid');
                    url = `${apiUrl}/localities?start=0&limit=50&order_by=population&order_type=desc&nid=IT`;
                }

                if(filter_by_lid){
                    return httpClient(url).then( data => {
                        let data_from_server = [];
                        data.json.localities.forEach(d => {

                            if(parseInt(d.lid) === parseInt(localityLid)) {
                                const row = {
                                    id: d.lid,
                                    name: d.name
                                };
                                data_from_server.push(row);
                                localStorage.removeItem('locality_lid');
                            }
                        });

                        return {
                            data: data_from_server,
                            total: data.json.resultSetCount
                        };
                    });
                }else{
                    return httpClient(url).then( data => {
                        let data_from_server = [];
                        data.json.localities.forEach(d => {
                            const row = {
                                id: d.lid,
                                name: d.name
                            };

                            data_from_server.push(row);
                        });

                        return {
                            data: data_from_server,
                            total: data.json.resultSetCount
                        };
                    });
                }
            });
        }

        if(resource === 'filter-localities'){
            return delay(200).then(function() {
                let url;
                let localityName = localStorage.getItem('locality_name');
                let localityLid = localStorage.getItem('locality_lid');
                let filter_by_lid = false;
                if(!isEmpty(params.filter)){
                    let filter_title = params.filter.title;
                    if(filter_title !== ""){
                        url = `${apiUrl}/localities?start=0&limit=50&name=${filter_title}&order_by=population&order_type=desc&nid=IT`;
                    }else{
                        if(localityName){
                            url = `${apiUrl}/localities?start=0&limit=50&name=${localityName}&order_by=population&order_type=desc&nid=IT`;
                            localStorage.removeItem('locality_name');
                            filter_by_lid = true;
                        }else{
                            url = `${apiUrl}/localities?start=0&limit=50&order_by=population&order_type=desc&nid=IT`;
                        }
                    }
                }else{
                    // localStorage.removeItem('locality_name');
                    // localStorage.removeItem('locality_lid');
                    url = `${apiUrl}/localities?start=0&limit=50&order_by=population&order_type=desc&nid=IT`;
                }

                if(filter_by_lid){
                    return httpClient(url).then( data => {
                        let data_from_server = [];
                        data_from_server[0] = {
                            id: null,
                            name: '--No filter--'
                        };
                        data.json.localities.forEach(d => {

                            if(parseInt(d.lid) === parseInt(localityLid)) {
                                const row = {
                                    id: d.lid,
                                    name: `${d.name} (${d.pid})`
                                };
                                data_from_server.push(row);
                                localStorage.removeItem('locality_lid');
                            }
                        });

                        return {
                            data: data_from_server,
                            total: data.json.resultSetCount
                        };
                    });
                }else{
                    return httpClient(url).then( data => {
                        let data_from_server = [];
                        data_from_server[0] = {
                            id: null,
                            name: '--No filter--'
                        };
                        data.json.localities.forEach(d => {
                            const row = {
                                id: d.lid,
                                name: `${d.name} (${d.pid})`
                            };

                            data_from_server.push(row);
                        });

                        return {
                            data: data_from_server,
                            total: data.json.resultSetCount
                        };
                    });
                }
            });
        }

        if(resource === 'webcams'){
            // return false;
            let { page, perPage } = params.pagination;
            let actual_page = parseInt(page) - 1;
            let start = parseInt(actual_page) * parseInt(perPage);

            let url = `${apiUrl}/${resource}?start=${start}&limit=${perPage}&active=1&valid=1`;

            let exact_sort_source;
            let sort_order = 'ASC';
            let filter_by_lid = false;
            if(!isEmpty(params.filter)){
                let filter_source = params.filter.source;
                let filter_lid = params.filter.lid;
                if(filter_lid !== undefined){
                    let showHamlet = document.getElementById('show_hamlet');
                    showHamlet.removeAttribute("disabled");
                    exact_sort_source = 'position';
                    sort_order = 'DESC';
                    filter_by_lid = true;
                    let includeParent = params.filter.show_hamlet ? 1 : 0;
                    url = `${apiUrl}/webcams/lid/${filter_lid}?start=${start}&limit=${perPage}&active=1&valid=1&include_parent=${includeParent}`
                }
                if(filter_source !== undefined){
                    url = url+`&source_type=${filter_source}`;
                }
            }

            if(!isEmpty(params.sort)){
                let sort_source = params.sort.field;
                switch (sort_source){
                    case 'id':
                        if(!filter_by_lid){
                            exact_sort_source = 'wid';
                        }
                        break;
                    case 'full_lid':
                        sort_order = params.sort.order;
                        exact_sort_source = 'lid';
                        break;
                    case 'source':
                        sort_order = params.sort.order;
                        exact_sort_source = 'source_type';
                        break;
                    case 'updatedAt':
                        sort_order = params.sort.order;
                        exact_sort_source = 'updated_at';
                        break;
                    default:
                        sort_order = params.sort.order;
                        exact_sort_source = sort_source
                }
            }

            url = url+`&order_by=${exact_sort_source}&order_type=${sort_order}`;

            return httpClient(url).then( data => {
                let data_from_server = [];
                data.json.webcams.forEach(d => {
                    const row = {
                        id: d.wid,
                        lid: d.locality.lid,
                        ...d
                    };

                    let source;
                    switch(d.sourceType) {
                        case 1: source = "Legacy"; break;
                        case 2: source = "Manual"; break;
                        case 3: source = "Skyline"; break;
                        case 4: source = "Windy"; break;
                        case 5: source = "Feratel"; break;
                        case 6: source = "LiveinCam"; break;
                        default: source = "";
                    }

                    row.source = source;
                    row.full_lid = `${d.locality.name} (${d.locality.lid})`;

                    data_from_server.push(row);
                });
                // localDataWebcams = data_from_server;
                return {
                    data: data_from_server,
                    total: data.json.resultSetCount
                };
            });
        }

        if(resource === 'housekeeping'){
            const { page, perPage } = params.pagination;
            // const { field, order } = params.sort;
            const actual_page = parseInt(page) - 1;
            const start = parseInt(actual_page) * parseInt(perPage);

            let url = `${apiUrl}/webcams?start=${start}&limit=${perPage}&active=1&valid=0`;

            if(!isEmpty(params.filter)){
                let filter_source = params.filter.source;
                let filter_lid = params.filter.lid;
                if(filter_lid !== undefined){
                    url = `${apiUrl}/webcams/lid/${filter_lid}?start=${start}&limit=${perPage}&active=1&valid=0`
                }
                if(filter_source !== undefined){
                    if(filter_source !== undefined){
                        url = url+`&source_type=${filter_source}`;
                    }
                }
            }

            if(!isEmpty(params.sort)){
                let sort_source = params.sort.field;
                let sort_order = params.sort.order;
                let exact_sort_source;
                switch (sort_source){
                    case 'id':
                        exact_sort_source = 'wid';
                        break;
                    case 'full_lid':
                        exact_sort_source = 'lid';
                        break;
                    case 'source':
                        exact_sort_source = 'source_type';
                        break;
                    case 'dateToUse':
                        exact_sort_source = 'updated_at';
                        break;
                    case 'days_off':
                        exact_sort_source = 'snippet_not_valid_since';
                        break;
                    default:
                        exact_sort_source = sort_source
                }

                url = url+`&order_by=${exact_sort_source}&order_type=${sort_order}`;
            }

            return httpClient(url).then( data => {
                const data_from_server = [];

                data.json.webcams.forEach(d => {
                    let dateToUse;
                    if(d.snippetNotValidSince == null){
                        dateToUse = d.updatedAt
                    }else{
                        dateToUse = d.snippetNotValidSince
                    }
                    const row = {
                        dateToUse: dateToUse,
                        id: d.wid,
                        lid: d.locality.lid,
                        ...d
                    };

                    let source;
                    switch(d.sourceType) {
                        case 1: source = "Legacy"; break;
                        case 2: source = "Manual"; break;
                        case 3: source = "Skyline"; break;
                        case 4: source = "Windy"; break;
                        case 5: source = "Feratel"; break;
                        case 6: source = "LiveinCam"; break;
                        default: source = "";
                    }

                    row.source = source;
                    row.status = d.housekeepingErrorMessage;
                    row.full_lid = `${d.locality.name} (${d.locality.lid})`;

                    let days_off;
                    // let date_to_use;
                    if(d.snippetNotValidSince === null){
                        days_off = moment().diff(moment(d.updatedAt), "days");
                    }else{
                        days_off = moment().diff(moment(d.snippetNotValidSince), "days");
                    }

                    // let days_off = moment().diff(moment(d.snippetNotValidSince), "days");
                    let days_off_text;
                    if(parseInt(days_off) === 1){
                        days_off_text = `${days_off} day`;
                    }else{
                        days_off_text = `${days_off} days`;
                    }
                    row.days_off = days_off_text;

                    data_from_server.push(row);
                });
                // localDataWebcams = data_from_server;

                return {
                    data: data_from_server,
                    total: data.json.resultSetCount
                };
            });
        }

        if(resource === 'reports'){
            let { page, perPage } = params.pagination;
            // let { field, order } = params.sort;
            let actual_page = parseInt(page) - 1;
            let start = parseInt(actual_page) * parseInt(perPage);

            let url = `${apiUrl}/${resource}?start=${start}&limit=${perPage}`;

            if(!isEmpty(params.filter)){
                let filter_status = params.filter.statusText;
                let filter_email = params.filter.email;
                let filter_city = params.filter.city;
                if(filter_status !== undefined){
                    url = url+`&status=${filter_status}`;
                }
                if(filter_city !== undefined){
                    url = url+`&city=${filter_city}`;
                }
                if(filter_email !== undefined){
                    url = url+`&email=${filter_email}`;
                }
            }

            if(!isEmpty(params.sort)){
                let sort_source = params.sort.field;
                let sort_order = params.sort.order;
                let exact_sort_source;
                switch (sort_source){
                    case 'id':
                        exact_sort_source = 'created_at';
                        sort_order = 'DESC';
                        break;
                    case 'city':
                        exact_sort_source = 'city';
                        break;
                    case 'statusText':
                        exact_sort_source = 'status';
                        break;
                    case 'createdAt':
                        exact_sort_source = 'updated_at';
                        break;
                    default:
                        exact_sort_source = sort_source
                }

                url = url+`&order_by=${exact_sort_source}&order_type=${sort_order}`;
            }

            // console.log(url);
            // return false;

            return httpClient(url).then( data => {
                let data_from_server = [];
                data.json.reports.forEach(d => {
                    const row = {
                        ...d
                    };

                    let statusText;
                    switch(d.status) {
                        case -1: statusText = "Rejected"; break;
                        case 0: statusText = "Pending"; break;
                        case 1: statusText = "Live"; break;
                        default: statusText = "";
                    }

                    row.statusText = statusText;
                    // row.full_lid = `${d.locality.name} (${d.locality.lid})`;

                    data_from_server.push(row);
                });
                // localDataWebcams = data_from_server;
                return {
                    data: data_from_server,
                    total: data.json.resultSetCount
                };
            });
        }

        if(resource === 'deleted-webcams'){
            // let current_object = webcamFakeData;

            const { page, perPage } = params.pagination;
            // const { field, order } = params.sort;
            const actual_page = parseInt(page) - 1;
            const start = parseInt(actual_page) * parseInt(perPage);

            let url = `${apiUrl}/webcams?start=${start}&limit=${perPage}&active=0`;

            if(!isEmpty(params.filter)){
                let filter_source = params.filter.source;
                let filter_lid = params.filter.lid;
                if(filter_lid !== undefined){
                    url = `${apiUrl}/webcams/lid/${filter_lid}?start=${start}&limit=${perPage}&active=0`
                }
                if(filter_source !== undefined){
                    if(filter_source !== undefined){
                        url = url+`&source_type=${filter_source}`;
                    }
                }
            }

            if(!isEmpty(params.sort)){
                let sort_source = params.sort.field;
                let sort_order = params.sort.order;
                let exact_sort_source;
                switch (sort_source){
                    case 'id':
                        exact_sort_source = 'wid';
                        break;
                    case 'full_lid':
                        exact_sort_source = 'lid';
                        break;
                    case 'source':
                        exact_sort_source = 'source_type';
                        break;
                    case 'updatedAt':
                        exact_sort_source = 'updated_at';
                        break;
                    default:
                        exact_sort_source = sort_source
                }

                url = url+`&order_by=${exact_sort_source}&order_type=${sort_order}`;
            }


            return httpClient(url).then( data => {
                const data_from_server = [];
                data.json.webcams.forEach(d => {
                    const row = {
                        id: d.wid,
                        lid: d.locality.lid,
                        ...d
                    };

                    let source;
                    switch(d.sourceType) {
                        case 1: source = "Legacy"; break;
                        case 2: source = "Manual"; break;
                        case 3: source = "Skyline"; break;
                        case 4: source = "Windy"; break;
                        case 5: source = "Feratel"; break;
                        case 6: source = "LiveinCam"; break;
                        default: source = "";
                    }

                    row.source = source;
                    row.status = "Contact is not webcams";
                    row.full_lid = `${d.locality.name} (${d.locality.lid})`;

                    data_from_server.push(row);
                });
                // localDataWebcams = data_from_server;

                return {
                    data: data_from_server,
                    total: data.json.resultSetCount
                };
            });
        }


        if(resource === 'highlighted'){
            let url = `${apiUrl}/localities/highlighted?type=${params.filter.type}&return_webcams=1&only_valid_webcams=1&only_active_webcams=1`;

            return httpClient(url).then( data => {
                let data_from_server = [];
                let position = 1;
                let maxPosition = parseInt(data.json.localities.length) + 1;
                data.json.localities.forEach(d => {

                    let numberOfValidAndActiveWebcams = 0;

                    d.webcams.forEach(webcam => {
                        if(parseInt(webcam.active) === 1 && parseInt(webcam.valid) === 1){
                            numberOfValidAndActiveWebcams++;
                        }
                    })

                    const row = {
                        id: d.lid,
                        lid: d.lid,
                        position: position,
                        type: params.filter.type,
                        // numberWebcams: d.webcams.length,
                        numberWebcams: numberOfValidAndActiveWebcams,
                        maxPosition: maxPosition,
                        ...d
                    };

                    row.full_lid = `${d.name} (${d.lid})`;

                    data_from_server.push(row);
                    position++;

                    const webcamsForLocation = d.webcams;
                    if(webcamsForLocation.length !== 0){
                        row.thumbnail = webcamsForLocation[0].thumbnail;
                    }else{
                        row.thumbnail = '';
                    }
                });

                // localStorage.setItem('highlighted', btoa(data_from_server));
                if(parseInt(params.filter.type) === 0){
                    array_of_localities_ids_type_zero = data_from_server;
                }else if(parseInt(params.filter.type) === 1){
                    array_of_localities_ids_type_one = data_from_server;
                }else if(parseInt(params.filter.type) === 2){
                    array_of_localities_ids_type_two = data_from_server;
                }else if(parseInt(params.filter.type) === 3){
                    array_of_localities_ids_type_three = data_from_server;
                }

                return {
                    data: data_from_server,
                    total: data_from_server.length
                };
            });
        }
    },

    create: (resource, params) => {
        if(resource === 'webcams') {
            const url = `${apiUrl}/webcams/lid/${params.data.lid}`;

            const correctParams = {};
            correctParams.name = params.data.name;
            if(params.data.description !== undefined){
                correctParams.description = params.data.description;
            }else{
                correctParams.description = '';
            }
            correctParams.snippet = params.data.snippet;
            correctParams.thumbnail = params.data.snippet;
            correctParams.position = params.data.position;

            return httpClient(`${url}`, {
                method: 'POST',
                body: JSON.stringify(correctParams),
            }).then(({ json }) => ({
                data: { ...params.data, id: json.webcam.wid },
            }))
        }

        if(resource === 'highlighted'){
            let index = 0;
            let highlighted_ids = [];
            let new_array_of_localities_ids = [];
            if(array_of_localities_ids === undefined || array_of_localities_ids.length === 0){
                return httpClient(`${apiUrl}/localities/highlighted`).then( data => {
                    let data_from_server = [];
                    let position = 1;
                    let maxPosition = parseInt(data.json.localities.length) + 1;
                    data.json.localities.forEach(d => {
                        const row = {
                            id: d.lid,
                            lid: d.lid,
                            position: position,
                            maxPosition: maxPosition,
                            ...d
                        };

                        row.full_lid = `${d.name} (${d.lid})`;

                        data_from_server.push(row);
                        position++;
                    });

                    // localStorage.setItem('highlighted', btoa(data_from_server));
                    array_of_localities_ids = data_from_server;
                    highlighted_ids = data_from_server;
                    let index_old_ids = 0;
                    highlighted_ids.forEach(d => {
                        new_array_of_localities_ids[index_old_ids] = d.id;
                        index_old_ids++;
                    });

                    index = index_old_ids;
                    let match_id_locality;
                    params.data.localities.forEach( locality => {
                        match_id_locality = false;
                        highlighted_ids.forEach(d => {
                            if(parseInt(d.id) === parseInt(locality.lid_ids)){
                                match_id_locality = true;
                            }
                        });
                        if(!match_id_locality){
                            new_array_of_localities_ids[index] = parseInt(locality.lid_ids);
                            index++;
                        }
                    });
                    return httpClient(`${apiUrl}/localities/highlighted`, {
                        method: 'POST',
                        body: JSON.stringify(new_array_of_localities_ids),
                    }).then(({ json }) => ({
                        data: { ...params.data, id: 1 },
                    }))
                });
            }else{
                highlighted_ids = array_of_localities_ids;
                let index_old_ids = 0;
                highlighted_ids.forEach(d => {
                    new_array_of_localities_ids[index_old_ids] = d.id;
                    index_old_ids++;
                });

                index = index_old_ids;
                let match_id_locality;
                params.data.localities.forEach( locality => {
                    match_id_locality = false;
                    highlighted_ids.forEach(d => {
                        if(parseInt(d.id) === parseInt(locality.lid_ids)){
                            match_id_locality = true;
                        }
                    });
                    if(!match_id_locality){
                        new_array_of_localities_ids[index] = parseInt(locality.lid_ids);
                        index++;
                    }
                });

                return httpClient(`${apiUrl}/localities/highlighted`, {
                    method: 'POST',
                    body: JSON.stringify(new_array_of_localities_ids),
                }).then(({ json }) => ({
                    data: { ...params.data, id: 1 },
                }))
            }
        }

        if(resource === 'highlighted-localities-top'){
            let index = 0;
            let highlighted_ids = [];
            let new_array_of_localities_ids = [];
            if(array_of_localities_ids_type_zero === undefined || array_of_localities_ids_type_zero.length === 0){
                return httpClient(`${apiUrl}/localities/highlighted?type=0&return_webcams=1`).then( data => {
                    let data_from_server = [];
                    let position = 1;
                    let maxPosition = parseInt(data.json.localities.length) + 1;
                    data.json.localities.forEach(d => {
                        const row = {
                            id: d.lid,
                            lid: d.lid,
                            type: 0,
                            position: position,
                            numberWebcams: d.webcams.length,
                            maxPosition: maxPosition,
                            ...d
                        };

                        row.full_lid = `${d.name} (${d.lid})`;

                        data_from_server.push(row);
                        position++;
                    });

                    // localStorage.setItem('highlighted', btoa(data_from_server));
                    array_of_localities_ids_type_zero = data_from_server;
                    highlighted_ids = data_from_server;
                    let index_old_ids = 0;
                    highlighted_ids.forEach(d => {
                        new_array_of_localities_ids[index_old_ids] = d.id;
                        index_old_ids++;
                    });

                    index = index_old_ids;
                    let match_id_locality;
                    params.data.localities.forEach( locality => {
                        match_id_locality = false;
                        highlighted_ids.forEach(d => {
                            if(parseInt(d.id) === parseInt(locality.lid_ids)){
                                match_id_locality = true;
                            }
                        });
                        if(!match_id_locality){
                            new_array_of_localities_ids[index] = parseInt(locality.lid_ids);
                            index++;
                        }
                    });
                    let data_body = {};
                    data_body.type = 0;
                    data_body.lids = new_array_of_localities_ids;

                    return httpClient(`${apiUrl}/localities/highlighted`, {
                        method: 'POST',
                        body: JSON.stringify(data_body),
                    }).then(({ json }) => ({
                        data: { ...params.data, id: 1 },
                    }))
                });
            }else{
                highlighted_ids = array_of_localities_ids_type_zero;
                let index_old_ids = 0;
                highlighted_ids.forEach(d => {
                    new_array_of_localities_ids[index_old_ids] = d.id;
                    index_old_ids++;
                });

                index = index_old_ids;
                let match_id_locality;
                params.data.localities.forEach( locality => {
                    match_id_locality = false;
                    highlighted_ids.forEach(d => {
                        if(parseInt(d.id) === parseInt(locality.lid_ids)){
                            match_id_locality = true;
                        }
                    });
                    if(!match_id_locality){
                        new_array_of_localities_ids[index] = parseInt(locality.lid_ids);
                        index++;
                    }
                });
                let data_body = {};
                data_body.type = 0;
                data_body.lids = new_array_of_localities_ids;
                return httpClient(`${apiUrl}/localities/highlighted`, {
                    method: 'POST',
                    body: JSON.stringify(data_body),
                }).then(({ json }) => ({
                    data: { ...params.data, id: 1 },
                }))
            }
        }

        if(resource === 'highlighted-localities-cities'){
            let index = 0;
            let highlighted_ids = [];
            let new_array_of_localities_ids = [];
            if(array_of_localities_ids_type_one === undefined || array_of_localities_ids_type_one.length === 0){
                return httpClient(`${apiUrl}/localities/highlighted?type=1&return_webcams=1`).then( data => {
                    let data_from_server = [];
                    let position = 1;
                    let maxPosition = parseInt(data.json.localities.length) + 1;
                    data.json.localities.forEach(d => {
                        const row = {
                            id: d.lid,
                            lid: d.lid,
                            type: 1,
                            numberWebcams: d.webcams.length,
                            position: position,
                            maxPosition: maxPosition,
                            ...d
                        };

                        row.full_lid = `${d.name} (${d.lid})`;

                        data_from_server.push(row);
                        position++;
                    });

                    array_of_localities_ids_type_one = data_from_server;
                    highlighted_ids = data_from_server;
                    let index_old_ids = 0;
                    highlighted_ids.forEach(d => {
                        new_array_of_localities_ids[index_old_ids] = d.id;
                        index_old_ids++;
                    });

                    index = index_old_ids;
                    let match_id_locality;
                    params.data.localities.forEach( locality => {
                        match_id_locality = false;
                        highlighted_ids.forEach(d => {
                            if(parseInt(d.id) === parseInt(locality.lid_ids)){
                                match_id_locality = true;
                            }
                        });
                        if(!match_id_locality){
                            new_array_of_localities_ids[index] = parseInt(locality.lid_ids);
                            index++;
                        }
                    });
                    let data_body = {};
                    data_body.type = 1;
                    data_body.lids = new_array_of_localities_ids;

                    return httpClient(`${apiUrl}/localities/highlighted`, {
                        method: 'POST',
                        body: JSON.stringify(data_body),
                    }).then(({ json }) => ({
                        data: { ...params.data, id: 1 },
                    }))
                });
            }else{
                highlighted_ids = array_of_localities_ids_type_one;
                let index_old_ids = 0;
                highlighted_ids.forEach(d => {
                    new_array_of_localities_ids[index_old_ids] = d.id;
                    index_old_ids++;
                });

                index = index_old_ids;
                let match_id_locality;
                params.data.localities.forEach( locality => {
                    match_id_locality = false;
                    highlighted_ids.forEach(d => {
                        if(parseInt(d.id) === parseInt(locality.lid_ids)){
                            match_id_locality = true;
                        }
                    });
                    if(!match_id_locality){
                        new_array_of_localities_ids[index] = parseInt(locality.lid_ids);
                        index++;
                    }
                });
                let data_body = {};
                data_body.type = 1;
                data_body.lids = new_array_of_localities_ids;
                return httpClient(`${apiUrl}/localities/highlighted`, {
                    method: 'POST',
                    body: JSON.stringify(data_body),
                }).then(({ json }) => ({
                    data: { ...params.data, id: 1 },
                }))
            }
        }

        if(resource === 'highlighted-localities-seaside'){
            let index = 0;
            let highlighted_ids = [];
            let new_array_of_localities_ids = [];
            if(array_of_localities_ids_type_two === undefined || array_of_localities_ids_type_two.length === 0){
                return httpClient(`${apiUrl}/localities/highlighted?type=2&return_webcams=1`).then( data => {
                    let data_from_server = [];
                    let position = 1;
                    let maxPosition = parseInt(data.json.localities.length) + 1;
                    data.json.localities.forEach(d => {
                        const row = {
                            id: d.lid,
                            lid: d.lid,
                            type: 2,
                            numberWebcams: d.webcams.length,
                            position: position,
                            maxPosition: maxPosition,
                            ...d
                        };

                        row.full_lid = `${d.name} (${d.lid})`;

                        data_from_server.push(row);
                        position++;
                    });

                    // localStorage.setItem('highlighted', btoa(data_from_server));
                    array_of_localities_ids_type_two = data_from_server;
                    highlighted_ids = data_from_server;
                    let index_old_ids = 0;
                    highlighted_ids.forEach(d => {
                        new_array_of_localities_ids[index_old_ids] = d.id;
                        index_old_ids++;
                    });

                    index = index_old_ids;
                    let match_id_locality;
                    params.data.localities.forEach( locality => {
                        match_id_locality = false;
                        highlighted_ids.forEach(d => {
                            if(parseInt(d.id) === parseInt(locality.lid_ids)){
                                match_id_locality = true;
                            }
                        });
                        if(!match_id_locality){
                            new_array_of_localities_ids[index] = parseInt(locality.lid_ids);
                            index++;
                        }
                    });
                    let data_body = {};
                    data_body.type = 2;
                    data_body.lids = new_array_of_localities_ids;

                    return httpClient(`${apiUrl}/localities/highlighted`, {
                        method: 'POST',
                        body: JSON.stringify(data_body),
                    }).then(({ json }) => ({
                        data: { ...params.data, id: 1 },
                    }))
                });
            }else{
                highlighted_ids = array_of_localities_ids_type_two;
                let index_old_ids = 0;
                highlighted_ids.forEach(d => {
                    new_array_of_localities_ids[index_old_ids] = d.id;
                    index_old_ids++;
                });

                index = index_old_ids;
                let match_id_locality;
                params.data.localities.forEach( locality => {
                    match_id_locality = false;
                    highlighted_ids.forEach(d => {
                        if(parseInt(d.id) === parseInt(locality.lid_ids)){
                            match_id_locality = true;
                        }
                    });
                    if(!match_id_locality){
                        new_array_of_localities_ids[index] = parseInt(locality.lid_ids);
                        index++;
                    }
                });
                let data_body = {};
                data_body.type = 2;
                data_body.lids = new_array_of_localities_ids;
                return httpClient(`${apiUrl}/localities/highlighted`, {
                    method: 'POST',
                    body: JSON.stringify(data_body),
                }).then(({ json }) => ({
                    data: { ...params.data, id: 1 },
                }))
            }
        }

        if(resource === 'highlighted-localities-mountains'){
            let index = 0;
            let highlighted_ids = [];
            let new_array_of_localities_ids = [];
            if(array_of_localities_ids_type_three === undefined || array_of_localities_ids_type_three.length === 0){
                return httpClient(`${apiUrl}/localities/highlighted?type=3&return_webcams=1`).then( data => {
                    let data_from_server = [];
                    let position = 1;
                    let maxPosition = parseInt(data.json.localities.length) + 1;
                    data.json.localities.forEach(d => {
                        const row = {
                            id: d.lid,
                            lid: d.lid,
                            type: 3,
                            position: position,
                            numberWebcams: d.webcams.length,
                            maxPosition: maxPosition,
                            ...d
                        };

                        row.full_lid = `${d.name} (${d.lid})`;

                        data_from_server.push(row);
                        position++;
                    });

                    // localStorage.setItem('highlighted', btoa(data_from_server));
                    array_of_localities_ids_type_three = data_from_server;
                    highlighted_ids = data_from_server;
                    let index_old_ids = 0;
                    highlighted_ids.forEach(d => {
                        new_array_of_localities_ids[index_old_ids] = d.id;
                        index_old_ids++;
                    });

                    index = index_old_ids;
                    let match_id_locality;
                    params.data.localities.forEach( locality => {
                        match_id_locality = false;
                        highlighted_ids.forEach(d => {
                            if(parseInt(d.id) === parseInt(locality.lid_ids)){
                                match_id_locality = true;
                            }
                        });
                        if(!match_id_locality){
                            new_array_of_localities_ids[index] = parseInt(locality.lid_ids);
                            index++;
                        }
                    });
                    let data_body = {};
                    data_body.type = 3;
                    data_body.lids = new_array_of_localities_ids;

                    return httpClient(`${apiUrl}/localities/highlighted`, {
                        method: 'POST',
                        body: JSON.stringify(data_body),
                    }).then(({ json }) => ({
                        data: { ...params.data, id: 1 },
                    }))
                });
            }else{
                highlighted_ids = array_of_localities_ids_type_three;
                let index_old_ids = 0;
                highlighted_ids.forEach(d => {
                    new_array_of_localities_ids[index_old_ids] = d.id;
                    index_old_ids++;
                });

                index = index_old_ids;
                let match_id_locality;
                params.data.localities.forEach( locality => {
                    match_id_locality = false;
                    highlighted_ids.forEach(d => {
                        if(parseInt(d.id) === parseInt(locality.lid_ids)){
                            match_id_locality = true;
                        }
                    });
                    if(!match_id_locality){
                        new_array_of_localities_ids[index] = parseInt(locality.lid_ids);
                        index++;
                    }
                });
                let data_body = {};
                data_body.type = 3;
                data_body.lids = new_array_of_localities_ids;
                return httpClient(`${apiUrl}/localities/highlighted`, {
                    method: 'POST',
                    body: JSON.stringify(data_body),
                }).then(({ json }) => ({
                    data: { ...params.data, id: 1 },
                }))
            }
        }

    },

    update: (resource, params) => {
        if(resource === 'webcams' || resource === 'housekeeping' || resource === 'deleted-webcams') {

            const correctParams = {};
            correctParams.lid = params.data.lid;
            correctParams.name = params.data.name;
            if (params.data.description !== undefined) {
                correctParams.description = params.data.description;
            } else {
                correctParams.description = '';
            }
            // correctParams.snippet = params.data.snippet;

            if(parseInt(params.data.sourceType) === 1 || parseInt(params.data.sourceType) === 2) {
                correctParams.snippet = params.data.snippet;
                correctParams.thumbnail = params.data.snippet;
            }
            // }else{
            //     correctParams.thumbnail = params.data.thumbnail;
            // }
            correctParams.active = params.data.active;
            correctParams.valid = params.data.valid;
            correctParams.position = params.data.position;
            return httpClient(`${apiUrl}/webcams/${params.id}`, {
                method: 'PUT',
                body: JSON.stringify(correctParams),
            }).then(({json}) => ({data: json.webcam}))
        }

        if(resource === 'highlighted'){
            // let highlighted = atob(localStorage.getItem('highlighted'));
            let highlighted = array_of_localities_ids;
            let array_of_id = [];
            let index = 0;
            let find_position = false;
            highlighted.forEach(d => {
                if(parseInt(params.id) !== parseInt(d.id)) {
                    if (d.position < params.data.position || d.position > params.data.position) {
                        array_of_id[index] = d.id
                    } else if (parseInt(d.position) === parseInt(params.data.position)) {
                        array_of_id[index] = parseInt(params.id);
                        index++;
                        array_of_id[index] = d.id;
                        find_position = true;
                    }
                    index++;
                }
            });
            if(!find_position){
                array_of_id[index] = parseInt(params.id);
            }

            let data_body = {};
            data_body.type = 0;
            data_body.lids = array_of_id;
            return httpClient(`${apiUrl}/localities/highlighted`, {
                method: 'POST',
                body: JSON.stringify(data_body),
            }).then(function (json) {
                let dataToReturn = [];
                array_of_id.forEach(element => {
                    array_of_localities_ids.forEach(singleHighlighted => {
                        if(parseInt(singleHighlighted.id) === parseInt(element)){
                            dataToReturn.push(singleHighlighted);
                        }
                    })
                });
                return {
                   data: dataToReturn
                }
            })
        }

        if(resource === 'highlighted-localities-top'){
            // let highlighted = atob(localStorage.getItem('highlighted'));
            let highlighted = array_of_localities_ids_type_zero;
            let array_of_id = [];
            let index = 0;
            let find_position = false;
            highlighted.forEach(d => {
                let value_to_control = index + 1;
                if(parseInt(value_to_control) === parseInt(params.data.position)){
                    array_of_id[index] = params.id;
                    index++;
                    find_position = true;
                }
                if(parseInt(params.id) !== parseInt(d.id)) {
                    array_of_id[index] = d.id;
                    index++;
                }
            });
            if(!find_position){
                array_of_id[index] = parseInt(params.id);
            }
            let data_body = {};
            data_body.type = 0;
            data_body.lids = array_of_id;
            return httpClient(`${apiUrl}/localities/highlighted`, {
                method: 'POST',
                body: JSON.stringify(data_body),
            }).then(function (json) {
                let dataToReturn = [];
                array_of_id.forEach(element => {
                    array_of_localities_ids.forEach(singleHighlighted => {
                        if(parseInt(singleHighlighted.id) === parseInt(element)){
                            dataToReturn.push(singleHighlighted);
                        }
                    })
                });
                return {
                    data: dataToReturn
                }
            })
        }

        if(resource === 'highlighted-localities-cities'){
            // let highlighted = atob(localStorage.getItem('highlighted'));
            let highlighted = array_of_localities_ids_type_one;
            let array_of_id = [];
            let index = 0;
            let find_position = false;
            highlighted.forEach(d => {
                let value_to_control = index + 1;
                if(parseInt(value_to_control) === parseInt(params.data.position)){
                    array_of_id[index] = params.id;
                    index++;
                    find_position = true;
                }
                if(parseInt(params.id) !== parseInt(d.id)) {
                    array_of_id[index] = d.id;
                    index++;
                }
            });
            if(!find_position){
                array_of_id[index] = parseInt(params.id);
            }
            let data_body = {};
            data_body.type = 1;
            data_body.lids = array_of_id;
            return httpClient(`${apiUrl}/localities/highlighted`, {
                method: 'POST',
                body: JSON.stringify(data_body),
            }).then(function (json) {
                let dataToReturn = [];
                array_of_id.forEach(element => {
                    array_of_localities_ids.forEach(singleHighlighted => {
                        if(parseInt(singleHighlighted.id) === parseInt(element)){
                            dataToReturn.push(singleHighlighted);
                        }
                    })
                });
                return {
                    data: dataToReturn
                }
            })
        }

        if(resource === 'highlighted-localities-seaside'){
            // let highlighted = atob(localStorage.getItem('highlighted'));
            let highlighted = array_of_localities_ids_type_two;
            let array_of_id = [];
            let index = 0;
            let find_position = false;
            highlighted.forEach(d => {
                let value_to_control = index + 1;
                if(parseInt(value_to_control) === parseInt(params.data.position)){
                    array_of_id[index] = params.id;
                    index++;
                    find_position = true;
                }
                if(parseInt(params.id) !== parseInt(d.id)) {
                    array_of_id[index] = d.id;
                    index++;
                }
            });
            if(!find_position){
                array_of_id[index] = parseInt(params.id);
            }
            let data_body = {};
            data_body.type = 2;
            data_body.lids = array_of_id;
            return httpClient(`${apiUrl}/localities/highlighted`, {
                method: 'POST',
                body: JSON.stringify(data_body),
            }).then(function (json) {
                let dataToReturn = [];
                array_of_id.forEach(element => {
                    array_of_localities_ids.forEach(singleHighlighted => {
                        if(parseInt(singleHighlighted.id) === parseInt(element)){
                            dataToReturn.push(singleHighlighted);
                        }
                    })
                });
                return {
                    data: dataToReturn
                }
            })
        }

        if(resource === 'highlighted-localities-mountains'){
            // let highlighted = atob(localStorage.getItem('highlighted'));
            let highlighted = array_of_localities_ids_type_three;
            let array_of_id = [];
            let index = 0;
            let find_position = false;
            highlighted.forEach(d => {
                let value_to_control = index + 1;
                if(parseInt(value_to_control) === parseInt(params.data.position)){
                    array_of_id[index] = params.id;
                    index++;
                    find_position = true;
                }
                if(parseInt(params.id) !== parseInt(d.id)) {
                    array_of_id[index] = d.id;
                    index++;
                }
            });
            if(!find_position){
                array_of_id[index] = parseInt(params.id);
            }
            let data_body = {};
            data_body.type = 3;
            data_body.lids = array_of_id;
            return httpClient(`${apiUrl}/localities/highlighted`, {
                method: 'POST',
                body: JSON.stringify(data_body),
            }).then(function (json) {
                let dataToReturn = [];
                array_of_id.forEach(element => {
                    array_of_localities_ids.forEach(singleHighlighted => {
                        if(parseInt(singleHighlighted.id) === parseInt(element)){
                            dataToReturn.push(singleHighlighted);
                        }
                    })
                });
                return {
                    data: dataToReturn
                }
            })
        }

        //is reports add as webcam
        if(resource === 'reports-add-as-webcam') {

            const url = `${apiUrl}/webcams/lid/${params.data.lid}`;

            const correctParamsReport = {};
            if(params.data.name !== undefined){
                correctParamsReport.name = params.data.name;
            }else{
                correctParamsReport.name = '';
            }

            if(params.data.description !== undefined){
                correctParamsReport.description = params.data.description;
            }else{
                correctParamsReport.description = '';
            }

            if(params.data.email !== undefined){
                correctParamsReport.email = params.data.email;
            }else{
                correctParamsReport.email = '';
            }

            if(params.data.city !== undefined){
                correctParamsReport.city = params.data.city;
            }else{
                correctParamsReport.city = '';
            }

            correctParamsReport.status = 1;
            correctParamsReport.lid = params.data.lid;
            correctParamsReport.webcam_url = params.data.webcam_url;


            const correctParams = {};
            correctParams.name = params.data.name;
            if(params.data.description !== undefined){
                correctParams.description = params.data.description;
            }else{
                correctParams.description = '';
            }
            correctParams.snippet = params.data.webcam_url;
            correctParams.thumbnail = params.data.webcam_url;

            return httpClient(`${url}`, {
                method: 'POST',
                body: JSON.stringify(correctParams),
            }).then(function (response) {
                correctParamsReport.wid = response.json.webcam.wid;
                return httpClient(`${apiUrl}/reports/${params.id}`, {
                    method: 'PUT',
                    body: JSON.stringify(correctParamsReport),
                }).then(({json}) => ({data: json.report}));
            });
        }


        //is reports add as webcam
        if(resource === 'reports') {

            const correctParamsReport = {};
            if(params.data.name !== undefined){
                correctParamsReport.name = params.data.name;
            }else{
                correctParamsReport.name = '';
            }
            if(params.data.description !== undefined){
                correctParamsReport.description = params.data.description;
            }else{
                correctParamsReport.description = '';
            }
            correctParamsReport.status = params.data.status;
            correctParamsReport.lid = params.data.lid;
            correctParamsReport.webcam_url = params.data.webcam_url;
            correctParamsReport.website = params.data.website;
            correctParamsReport.city = params.data.city;
            correctParamsReport.email = params.data.email;

            const correctParams = {};
            correctParams.name = params.data.name;
            if(params.data.description !== undefined){
                correctParams.description = params.data.description;
            }else{
                correctParams.description = '';
            }
            correctParams.snippet = params.data.webcam_url;
            correctParams.thumbnail = params.data.webcam_url;

            return httpClient(`${apiUrl}/reports/${params.id}`, {
                method: 'PUT',
                body: JSON.stringify(correctParamsReport),
            }).then(({json}) => ({data: json.report}));
        }
    },

    getOne: (resource, params) => {
        if(resource === 'webcams' || resource === 'housekeeping' || resource === 'deleted-webcams') {
            let url = `${apiUrl}/webcams/${params.id}`;
            // promiseInAct = httpClient(url).then(data => {});
            return httpClient(url).then(data => {
                localStorage.setItem('locality_name', data.json.webcam[0].locality.name);
                localStorage.setItem('locality_lid', data.json.webcam[0].locality.lid);
                let data_from_server = [];
                data.json.webcam.forEach(d => {
                    const row = {
                        id: d.wid,
                        lid: d.locality.lid,
                        ...d
                    };

                    let source;
                    switch(d.sourceType) {
                        case 1: source = "Legacy"; break;
                        case 2: source = "Manual"; break;
                        case 3: source = "Skyline"; break;
                        case 4: source = "Windy"; break;
                        case 5: source = "Feratel"; break;
                        default: source = "";
                    }

                    row.source = source;
                    row.status = d.housekeepingErrorMessage;
                    row.full_lid = `${d.locality.name} (${d.locality.lid})`;
                    data_from_server.push(row);
                });
                return {
                    data: data_from_server[0]
                }
            });
        }

        if(resource === 'highlighted'){
            let idH = params.id.split("-")[0];
            let typeH = params.id.split("-")[1];
            // return false;
            let url = `${apiUrl}/localities/highlighted?type=${typeH}`;
            return httpClient(url).then( data => {
                let data_from_server = [];
                let data_to_save = [];
                let position = 1;
                let index = 1;
                // let allPosition = [];
                let maxPosition = parseInt(data.json.localities.length) + 1;


                data.json.localities.forEach(d => {
                    const row_to_save = {
                        id: d.lid,
                        lid: d.lid,
                        position: index,
                        type: typeH,
                        maxPosition: maxPosition,
                        ...d
                    };

                    row_to_save.full_lid = `${d.name} (${d.lid})`;

                    data_to_save.push(row_to_save);
                    index++;
                });

                array_of_localities_ids = data_to_save;
                // localStorage.setItem('highlighted', btoa(data_to_save));


                data.json.localities.forEach(d => {
                    if(parseInt(d.lid) === parseInt(idH)) {
                        const row = {
                            id: d.lid,
                            lid: d.lid,
                            position: position,
                            maxPosition: maxPosition,
                            ...d
                        };

                        row.full_lid = `${d.name} (${d.lid})`;

                        data_from_server.push(row);
                    }
                    position++;
                });
                return {
                    data: data_from_server,
                };
            });
        }

        if(resource === 'highlighted-localities-top'){
            let url = `${apiUrl}/localities/highlighted?type=0&return_webcams=1`;
            return httpClient(url).then( data => {
                let data_from_server = [];
                let data_to_save = [];
                let position = 1;
                let index = 1;
                // let allPosition = [];
                let maxPosition = parseInt(data.json.localities.length) + 1;

                data.json.localities.forEach(d => {
                    const row_to_save = {
                        id: d.lid,
                        lid: d.lid,
                        type: 0,
                        position: index,
                        numberWebcams: d.webcams.length,
                        maxPosition: maxPosition,
                        ...d
                    };

                    row_to_save.full_lid = `${d.name} (${d.lid})`;

                    data_to_save.push(row_to_save);
                    index++;
                });

                array_of_localities_ids_type_zero = data_to_save;
                // localStorage.setItem('highlighted', btoa(data_to_save));

                data.json.localities.forEach(d => {
                    if(parseInt(d.lid) === parseInt(params.id)) {
                        const row = {
                            id: d.lid,
                            lid: d.lid,
                            type: 0,
                            position: position,
                            numberWebcams: d.webcams.length,
                            maxPosition: maxPosition,
                            ...d
                        };

                        row.full_lid = `${d.name} (${d.lid})`;

                        data_from_server.push(row);
                    }
                    position++;
                });
                return {
                    data: data_from_server[0],
                };
            });
        }

        if(resource === 'highlighted-localities-cities'){
            let url = `${apiUrl}/localities/highlighted?type=1&return_webcams=1`;
            return httpClient(url).then( data => {
                let data_from_server = [];
                let data_to_save = [];
                let position = 1;
                let index = 1;
                // let allPosition = [];
                let maxPosition = parseInt(data.json.localities.length) + 1;


                data.json.localities.forEach(d => {
                    const row_to_save = {
                        id: d.lid,
                        lid: d.lid,
                        type: 1,
                        position: index,
                        numberWebcams: d.webcams.length,
                        maxPosition: maxPosition,
                        ...d
                    };

                    row_to_save.full_lid = `${d.name} (${d.lid})`;

                    data_to_save.push(row_to_save);
                    index++;
                });

                array_of_localities_ids_type_one = data_to_save;
                // localStorage.setItem('highlighted', btoa(data_to_save));


                data.json.localities.forEach(d => {
                    if(parseInt(d.lid) === parseInt(params.id)) {
                        const row = {
                            id: d.lid,
                            lid: d.lid,
                            type: 1,
                            position: position,
                            numberWebcams: d.webcams.length,
                            maxPosition: maxPosition,
                            ...d
                        };

                        row.full_lid = `${d.name} (${d.lid})`;

                        data_from_server.push(row);
                    }
                    position++;
                });
                return {
                    data: data_from_server[0],
                };
            });
        }

        if(resource === 'highlighted-localities-seaside'){
            let url = `${apiUrl}/localities/highlighted?type=2&return_webcams=1`;
            return httpClient(url).then( data => {
                let data_from_server = [];
                let data_to_save = [];
                let position = 1;
                let index = 1;
                // let allPosition = [];
                let maxPosition = parseInt(data.json.localities.length) + 1;


                data.json.localities.forEach(d => {
                    const row_to_save = {
                        id: d.lid,
                        lid: d.lid,
                        type: 2,
                        position: index,
                        numberWebcams: d.webcams.length,
                        maxPosition: maxPosition,
                        ...d
                    };

                    row_to_save.full_lid = `${d.name} (${d.lid})`;

                    data_to_save.push(row_to_save);
                    index++;
                });

                array_of_localities_ids_type_two = data_to_save;
                // localStorage.setItem('highlighted', btoa(data_to_save));


                data.json.localities.forEach(d => {
                    if(parseInt(d.lid) === parseInt(params.id)) {
                        const row = {
                            id: d.lid,
                            lid: d.lid,
                            type: 2,
                            position: position,
                            numberWebcams: d.webcams.length,
                            maxPosition: maxPosition,
                            ...d
                        };

                        row.full_lid = `${d.name} (${d.lid})`;

                        data_from_server.push(row);
                    }
                    position++;
                });
                return {
                    data: data_from_server[0],
                };
            });
        }

        if(resource === 'highlighted-localities-mountains'){
            let url = `${apiUrl}/localities/highlighted?type=3&return_webcams=1`;
            return httpClient(url).then( data => {
                let data_from_server = [];
                let data_to_save = [];
                let position = 1;
                let index = 1;
                // let allPosition = [];
                let maxPosition = parseInt(data.json.localities.length) + 1;


                data.json.localities.forEach(d => {
                    const row_to_save = {
                        id: d.lid,
                        lid: d.lid,
                        type: 3,
                        numberWebcams: d.webcams.length,
                        position: index,
                        maxPosition: maxPosition,
                        ...d
                    };

                    row_to_save.full_lid = `${d.name} (${d.lid})`;

                    data_to_save.push(row_to_save);
                    index++;
                });

                array_of_localities_ids_type_three = data_to_save;
                // localStorage.setItem('highlighted', btoa(data_to_save));


                data.json.localities.forEach(d => {
                    if(parseInt(d.lid) === parseInt(params.id)) {
                        const row = {
                            id: d.lid,
                            lid: d.lid,
                            type: 2,
                            position: position,
                            numberWebcams: d.webcams.length,
                            maxPosition: maxPosition,
                            ...d
                        };

                        row.full_lid = `${d.name} (${d.lid})`;

                        data_from_server.push(row);
                    }
                    position++;
                });
                return {
                    data: data_from_server[0],
                };
            });
        }

        if(resource === 'reports'){
            // let url = `${apiUrl}/reports/${params.id}`;
            let url = `${apiUrl}/reports`;
            return httpClient(url).then(data => {
                let data_from_server = [];
                data.json.reports.forEach(d => {
                    if(parseInt(d.id) === parseInt(params.id)){
                        const row = {
                            ...d
                        };

                        localStorage.setItem('name_lid', d.city);
                        localStorage.setItem('locality_name', d.city);
                        localStorage.setItem('locality_lid', d.lid);

                        let statusText;
                        switch(d.status) {
                            case -1: statusText = "Rejected"; break;
                            case 0: statusText = "Pending"; break;
                            case 1: statusText = "Live"; break;
                            default: statusText = "";
                        }

                        row.statusText = statusText;
                        data_from_server.push(row);
                    }
                });
                return {
                    data: data_from_server[0]
                }
            });
        }

        if(resource === 'reports-add-as-webcam'){
            // let url = `${apiUrl}/reports/${params.id}`;
            let url = `${apiUrl}/reports`;
            return httpClient(url).then(data => {
                let data_from_server = [];
                data.json.reports.forEach(d => {
                    if(parseInt(d.id) === parseInt(params.id)){
                        const row = {
                            ...d
                        };

                        localStorage.setItem('name_lid', d.city);
                        localStorage.setItem('locality_name', d.city);
                        localStorage.setItem('locality_lid', d.lid);

                        let statusText;
                        switch(d.status) {
                            case -1: statusText = "Rejected"; break;
                            case 0: statusText = "Pending"; break;
                            case 1: statusText = "Approved"; break;
                            default: statusText = "";
                        }

                        row.statusText = statusText;
                        data_from_server.push(row);
                    }
                });
                return {
                    data: data_from_server[0]
                }
            });
        }
    },

    delete: (resource, params) => {
        if(resource === 'highlighted' || resource === 'highlighted-localities-top' || resource === 'highlighted-localities-cities' || resource === 'highlighted-localities-seaside' || resource === 'highlighted-localities-mountains'){
            let array_of_localities_ids_to_delete;
            let type = params.previousData.type;
            if(parseInt(type) === 0){
                array_of_localities_ids_to_delete = array_of_localities_ids_type_zero;
            }else if(parseInt(type) === 1){
                array_of_localities_ids_to_delete = array_of_localities_ids_type_one;
            }else if(parseInt(type) === 2){
                array_of_localities_ids_to_delete = array_of_localities_ids_type_two;
            }else if(parseInt(type) === 3){
                array_of_localities_ids_to_delete = array_of_localities_ids_type_three;
            }


            // let index = 0;
            let highlighted_ids = [];
            let new_array_of_localities_ids = [];
            if(array_of_localities_ids_to_delete === undefined || array_of_localities_ids_to_delete.length === 0){
                return httpClient(`${apiUrl}/localities/highlighted?type=${type}&return_webcams=1`).then( data => {
                    let data_from_server = [];
                    let position = 1;
                    let maxPosition = parseInt(data.json.localities.length) + 1;
                    data.json.localities.forEach(d => {
                        const row = {
                            id: d.lid,
                            lid: d.lid,
                            type: type,
                            position: position,
                            numberWebcams: d.webcams.length,
                            maxPosition: maxPosition,
                            ...d
                        };

                        row.full_lid = `${d.name} (${d.lid})`;

                        data_from_server.push(row);
                        position++;
                    });

                    // localStorage.setItem('highlighted', btoa(data_from_server));
                    array_of_localities_ids_to_delete = data_from_server;
                    highlighted_ids = data_from_server;
                    let index_old_ids = 0;
                    highlighted_ids.forEach(d => {
                        if(parseInt(d.id) !== parseInt(params.id)){
                            new_array_of_localities_ids[index_old_ids] = d.id;
                            index_old_ids++;
                        }
                    });

                    let data_body = {};
                    data_body.type = type;
                    data_body.lids = new_array_of_localities_ids;
                    return httpClient(`${apiUrl}/localities/highlighted`, {
                        method: 'POST',
                        body: JSON.stringify(data_body),
                    }).then(({ json }) => ({
                        data: { ...params.data, id: 1 },
                    }))
                });
            }else{
                highlighted_ids = array_of_localities_ids_to_delete;
                let index_old_ids = 0;
                highlighted_ids.forEach(d => {
                    if(parseInt(d.id) !== parseInt(params.id)){
                        new_array_of_localities_ids[index_old_ids] = d.id;
                        index_old_ids++;
                    }
                });
                let data_body = {};
                data_body.type = type;
                data_body.lids = new_array_of_localities_ids;

                return httpClient(`${apiUrl}/localities/highlighted`, {
                    method: 'POST',
                    body: JSON.stringify(data_body),
                    previousData: params.previousData,
                }).then(({ json }) => ({ data: {result: "deleted"} }));
            }
        }
        else if(resource === 'reports'){
            const correctParamsReport = {};
            if(params.previousData.name !== undefined){
                correctParamsReport.name = params.previousData.name;
            }else{
                correctParamsReport.name = '';
            }
            if(params.previousData.description !== undefined){
                correctParamsReport.description = params.previousData.description;
            }else{
                correctParamsReport.description = '';
            }
            correctParamsReport.lid = params.previousData.lid;
            correctParamsReport.webcam_url = params.previousData.webcam_url;
            correctParamsReport.website = params.previousData.website;
            correctParamsReport.city = params.previousData.city;
            correctParamsReport.email = params.previousData.email;
            correctParamsReport.status = -1;

            return httpClient(`${apiUrl}/reports/${params.id}`, {
                method: 'PUT',
                body: JSON.stringify(correctParamsReport),
            }).then(({json}) => ({data: json.report}));

        } else{
            let arrayOId = [];
            arrayOId[0] = params.id;
            return httpClient(`${apiUrl}/webcams`, {
                method: 'DELETE',
                body: JSON.stringify(arrayOId)
            }).then(({ json }) => ({ data: {result: "deleted"} }));
        }
    },

    deleteMany: (resource, params) => {
        if(resource === 'highlighted'){
            let highlighted_ids = [];
            let new_array_of_localities_ids = [];
            highlighted_ids = array_of_localities_ids;
            let index_old_ids = 0;
            highlighted_ids.forEach(d => {
                let match = false;
                params.ids.forEach(id => {
                    if(parseInt(d.id) === parseInt(id)){
                        match = true;
                    }
                });
                if(!match){
                    new_array_of_localities_ids[index_old_ids] = d.id;
                    index_old_ids++;
                }
            });


            return httpClient(`${apiUrl}/localities/highlighted`, {
                method: 'POST',
                body: JSON.stringify(new_array_of_localities_ids),
                previousData: params.previousData,
            }).then(({ json }) => ({ data: {result: "deleted"} }));
        }
        else{
            // let ids = [];
            return httpClient(`${apiUrl}/webcams`, {
                method: 'DELETE',
                body: JSON.stringify(params.ids)
            }).then(({ json }) => ({ data: {result: "deleted"} }));
        }
    },

    getMany: (resource, params) => {
        if(resource === 'localities'){
            return delay(200).then(function() {
                let url;
                let localityName = localStorage.getItem('locality_name');
                let localityLid = localStorage.getItem('locality_lid');
                let filter_by_lid = false;
                // console.log(localityName);
                // console.log(localityLid);
                if(!isEmpty(params.filter)){
                    let filter_title = params.filter.title;
                    // localStorage.removeItem('locality_name');
                    // localStorage.removeItem('locality_lid');
                    if(filter_title !== ""){
                        url = `${apiUrl}/localities?start=0&limit=50&name=${filter_title}&order_by=population&order_type=desc&nid=IT`;
                    }else{
                        if(localityName){
                            url = `${apiUrl}/localities?start=0&limit=50&name=${localityName}&order_by=population&order_type=desc&nid=IT`;
                            localStorage.removeItem('locality_name');
                            localStorage.removeItem('locality_name');
                            filter_by_lid = true;
                        }else{
                            url = `${apiUrl}/localities?start=0&limit=50&order_by=population&order_type=desc&nid=IT`;
                        }
                    }
                }else{
                    url = `${apiUrl}/localities?start=0&limit=50&order_by=population&order_type=desc&nid=IT`;
                    // localStorage.removeItem('locality_name');
                    // localStorage.removeItem('locality_lid');
                }

                if(filter_by_lid){
                    return httpClient(url).then( data => {
                        let data_from_server = [];
                        data.json.localities.forEach(d => {

                            if(parseInt(d.lid) === parseInt(localityLid)) {
                                const row = {
                                    id: d.lid,
                                    name: d.name
                                };
                                data_from_server.push(row);
                                localStorage.removeItem('locality_lid');
                            }
                        });

                        return {
                            data: data_from_server,
                            total: data.json.resultSetCount
                        };
                    });
                }else{
                    return httpClient(url).then( data => {
                        let data_from_server = [];
                        data.json.localities.forEach(d => {
                            const row = {
                                id: d.lid,
                                name: d.name
                            };

                            data_from_server.push(row);
                        });

                        return {
                            data: data_from_server,
                            total: data.json.resultSetCount
                        };
                    });
                }
            });
        }

        if(resource === 'filter-localities'){
            return delay(200).then(function() {
                let url;
                let localityName = localStorage.getItem('locality_name');
                let localityLid = localStorage.getItem('locality_lid');
                let filter_by_lid = false;
                if(!isEmpty(params.filter)){
                    let filter_title = params.filter.title;
                    if(filter_title !== ""){
                        url = `${apiUrl}/localities?start=0&limit=50&name=${filter_title}&order_by=population&order_type=desc&nid=IT`;
                    }else{
                        if(localityName){
                            url = `${apiUrl}/localities?start=0&limit=50&name=${localityName}&order_by=population&order_type=desc&nid=IT`;
                            localStorage.removeItem('locality_name');
                            filter_by_lid = true;
                        }else{
                            url = `${apiUrl}/localities?start=0&limit=50&order_by=population&order_type=desc&nid=IT`;
                        }
                    }
                }else{
                    // localStorage.removeItem('locality_name');
                    // localStorage.removeItem('locality_lid');
                    url = `${apiUrl}/localities?start=0&limit=50&order_by=population&order_type=desc&nid=IT`;
                }

                if(filter_by_lid){
                    return httpClient(url).then( data => {
                        let data_from_server = [];
                        data_from_server[0] = {
                            id: null,
                            name: '--No filter--'
                        };
                        data.json.localities.forEach(d => {

                            if(parseInt(d.lid) === parseInt(localityLid)) {
                                const row = {
                                    id: d.lid,
                                    name: `${d.name} (${d.pid})`
                                };
                                data_from_server.push(row);
                                localStorage.removeItem('locality_lid');
                            }
                        });

                        return {
                            data: data_from_server,
                            total: data.json.resultSetCount
                        };
                    });
                }else{
                    return httpClient(url).then( data => {
                        let data_from_server = [];
                        data_from_server[0] = {
                            id: null,
                            name: '--No filter--'
                        };
                        data.json.localities.forEach(d => {
                            const row = {
                                id: d.lid,
                                name: `${d.name} (${d.pid})`
                            };

                            data_from_server.push(row);
                        });

                        return {
                            data: data_from_server,
                            total: data.json.resultSetCount
                        };
                    });
                }
            });
        }

        if(resource === 'webcams'){
            let name_lid = localStorage.getItem('name_lid');
            let url;
            if(name_lid){
                url = `${apiUrl}/localities?start=0&limit=50&name=${name_lid}`;
                localStorage.removeItem('name_lid');
            }else{
                url = `${apiUrl}/localities?start=0&limit=50`;
            }
            return httpClient(url).then( data => {
                let data_from_server = [];
                data.json.localities.forEach(d => {
                    const row = {
                        id: d.lid,
                        name: d.name
                    };

                    data_from_server.push(row);
                });
                return {
                    data: data_from_server,
                    total: data.json.resultSetCount
                };
            });
        }
    },
}